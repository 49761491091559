import React from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  useGetCoachSiteTrialQuery,
  useGetCoachUserQuery,
} from '@fdha/graphql-api-admin';

import { BasePage, UserIdentifier } from '../../../components';

import { AddOrEditCoachSiteTrial } from './AddOrEditCoachSiteTrial';

const EditCoachSiteTrial = () => {
  const navigate = useNavigate();
  const params = useParams();
  const coachId = params.coachId || '';
  const coachSiteTrialId = params.coachSiteTrialId || '';

  const { data: coachSiteTrialData, loading: loadingCoachSiteTrial } =
    useGetCoachSiteTrialQuery({
      variables: { id: coachSiteTrialId },
      fetchPolicy: 'cache-and-network',
    });

  const { data: coachData, loading: coachLoading } = useGetCoachUserQuery({
    variables: {
      id: coachId,
    },
  });

  const coachSiteTrial = coachSiteTrialData?.coachSiteTrial;

  return (
    <BasePage>
      <BasePage.BackButton />
      <BasePage.Wrapper>
        <BasePage.Title>
          <>
            Edit Site/Trial Assignment
            <UserIdentifier
              coachName={coachData?.coachUser?.name}
              showSkeleton={coachLoading}
            />
          </>
        </BasePage.Title>
        <AddOrEditCoachSiteTrial
          onCancel={() => navigate(-1)}
          onSuccess={(coachId) => navigate(`/coach/${coachId}`)}
          data={coachSiteTrial}
          loadingCoachSiteTrial={loadingCoachSiteTrial}
          coachId={coachId}
        />
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default EditCoachSiteTrial;
