import { KeyOfNestedLeaves } from '@fdha/web-ui-library/src/types/keyOfNestedLeaves';
import { useState } from 'react';
import { FilterByField, FilterOp } from '@fdha/graphql-api-admin';

type Field<T extends object> = KeyOfNestedLeaves<T>;

export function useFilterBy<T extends object>(
  field: Field<T>,
  value: string,
  op?: FilterOp
): [FilterByField, (field: Field<T>, value: string, op?: FilterOp) => void] {
  const [filterBy, setFilterBy] = useState<FilterByField>(
    asFilterByField<T>(field, value, op)
  );

  const onChange = (field: Field<T>, value: string, op?: FilterOp) => {
    setFilterBy(asFilterByField<T>(field, value, op));
  };

  return [filterBy, onChange];
}

function asFilterByField<T extends object>(
  field: Field<T>,
  value: string,
  op: FilterOp = FilterOp.Includes
): FilterByField {
  return {
    field: field.toString(),
    op,
    value,
  };
}
