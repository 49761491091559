import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetGroupRecipeDocument,
  ListGroupRecipesDocument,
  useAssignRecipesToGroupMutation,
  useUnassignRecipesFromGroupMutation,
} from '@fdha/graphql-api-admin';
import { useSnackbar } from '@fdha/web-ui-library';

import RecipesTable from './RecipesTable';
import RecipeDetails from './RecipeDetails';

const Recipes = () => {
  const params = useParams();
  const { showSnackbar } = useSnackbar();

  const [assignRecipesToGroup] = useAssignRecipesToGroupMutation();
  const [unassignRecipesFromGroup] = useUnassignRecipesFromGroupMutation();

  const groupId = params.groupId || '';

  const [selectedRecipe, setSelectedRecipe] = useState<string | null>(null);

  const onChangeGroupVisibility = async (isVisible: boolean, id: string) => {
    try {
      if (isVisible) {
        await unassignRecipesFromGroup({
          variables: {
            groupId,
            recipeIds: [id],
          },
          refetchQueries: [ListGroupRecipesDocument, GetGroupRecipeDocument],
        });
      } else {
        await assignRecipesToGroup({
          variables: {
            groupId,
            recipeIds: [id],
          },
          refetchQueries: [ListGroupRecipesDocument, GetGroupRecipeDocument],
        });
      }
    } catch (error) {
      console.error(error);
      showSnackbar({
        message: 'Error changing recipe visibility to this group',
        severity: 'error',
      });
    }
  };

  const onCopyLink = (recipeLink: string) => {
    window.navigator.clipboard.writeText(recipeLink);
    showSnackbar({
      message: 'Link copied',
      severity: 'success',
    });
  };

  return selectedRecipe ? (
    <RecipeDetails
      groupId={groupId}
      recipeId={selectedRecipe}
      handleBack={() => setSelectedRecipe(null)}
      onChangeGroupVisibility={onChangeGroupVisibility}
      onCopyLink={onCopyLink}
    />
  ) : (
    <RecipesTable
      groupId={groupId}
      setRecipe={setSelectedRecipe}
      onChangeGroupVisibility={onChangeGroupVisibility}
      onCopyLink={onCopyLink}
    />
  );
};

export default Recipes;
