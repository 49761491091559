import { Stack } from '@mui/material';

import AllAssignedSchedules from './AllAssignedSchedules';
import AssignableSurveysList from './AssignableSurveysList';

const Schedules = () => {
  return (
    <Stack spacing={4} sx={{ width: '100%' }}>
      <AllAssignedSchedules />
      <AssignableSurveysList />
    </Stack>
  );
};

export default Schedules;
