import { Maybe } from '@fdha/graphql-api-admin';
import { Typography, TypographyProps } from '@fdha/web-ui-library';
import { useTheme } from '@mui/material';
import { omit } from 'lodash';
import React, { FC } from 'react';

interface PatientIdentifierProps {
  trialName?: Maybe<string>;
  patientName?: Maybe<string>;
  subjectId?: Maybe<string>;
}

interface CoachIdentifierProps {
  coachName?: Maybe<string>;
}

type UserIdentifierProps = (PatientIdentifierProps | CoachIdentifierProps) &
  Omit<TypographyProps, 'children'>;

const UserIdentifier: FC<UserIdentifierProps> = (props) => {
  const theme = useTheme();

  const getText = () => {
    if ('coachName' in props && !!props.coachName) {
      return props.coachName;
    }

    if (
      'patientName' in props &&
      !!props.patientName &&
      !!props.subjectId &&
      !!props.trialName
    ) {
      return `${props.trialName} | ${props.patientName} : ${props.subjectId}`;
    }

    return null;
  };

  const text = getText();

  if (!text && !('showSkeleton' in props)) {
    return null;
  }

  return (
    <Typography
      variant="body1"
      color={theme.palette.text.secondary}
      marginTop={0.5}
      {...omit(props, ['coachName', 'trialName', 'patientName', 'subjectId'])}
    >
      {text}
    </Typography>
  );
};

export default UserIdentifier;
