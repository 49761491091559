import React from 'react';
import { BasePage, Loader } from '@fdha/web-ui-library';
import {
  useGetFoodProgramUserQuery,
  DeliverySettings as DeliverySettingsTypes,
  useGetPatientUserQuery,
} from '@fdha/graphql-api-admin';
import { Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getConcatWeekdaysOnDiet,
  getWeekdaysOnDietDeliveries,
} from '@fdha/common-utils';
import { isEqual } from 'lodash';

import { UserIdentifier } from '../../../../components';

import SameDeliverySchedule from './SameDeliverySchedule';
import DifferentDeliverySchedules from './DifferentDeliverySchedules';

const DeliverySettings = () => {
  const navigate = useNavigate();
  const params = useParams();
  const patientId = params.patientId || '';

  const { data: foodProgramData, loading } = useGetFoodProgramUserQuery({
    variables: {
      patientId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !patientId,
  });

  const { data: patientData, loading: patientLoading } = useGetPatientUserQuery(
    {
      variables: {
        id: patientId,
      },
      fetchPolicy: 'cache-and-network',
      skip: !patientId,
    }
  );

  const subjectId =
    foodProgramData?.foodProgramUserByPatientIdV2?.patient.subject_id || '';

  const userDeliveryMenus =
    foodProgramData?.foodProgramUserByPatientIdV2?.userDeliveryMenus || [];

  const weekdays = getConcatWeekdaysOnDiet(userDeliveryMenus);

  const selectedSetting =
    foodProgramData?.foodProgramUserByPatientIdV2?.deliverySettings ||
    DeliverySettingsTypes.Freshest;

  const freshestResult = getWeekdaysOnDietDeliveries(
    weekdays,
    DeliverySettingsTypes.Freshest
  );

  const fewestResult = getWeekdaysOnDietDeliveries(
    weekdays,
    DeliverySettingsTypes.Fewest
  );

  const resultsAreTheSame = isEqual(freshestResult, fewestResult);

  if (loading || patientLoading) {
    return <Loader />;
  }

  return (
    <BasePage>
      <BasePage.BackButton handleClick={() => navigate(-1)} />
      <BasePage.Wrapper>
        <BasePage.Title>
          <>
            Delivery Settings
            <UserIdentifier
              trialName={patientData?.patientUser?.trial?.protocol_number}
              patientName={
                foodProgramData?.foodProgramUserByPatientIdV2?.patient.name
              }
              subjectId={subjectId}
            />
          </>
        </BasePage.Title>

        <Paper sx={{ padding: 3 }}>
          {resultsAreTheSame ? (
            <SameDeliverySchedule result={freshestResult} />
          ) : (
            <DifferentDeliverySchedules
              foodProgramUserId={
                foodProgramData?.foodProgramUserByPatientIdV2?.id || ''
              }
              subjectId={subjectId}
              userDeliveryMenus={userDeliveryMenus}
              selectedSetting={selectedSetting}
              weekdays={weekdays}
            />
          )}
        </Paper>
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default DeliverySettings;
