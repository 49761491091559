import React, { FC, useEffect, useState } from 'react';
import {
  GroupRecipeBase,
  useListGroupRecipesQuery,
} from '@fdha/graphql-api-admin';
import {
  HeadCell,
  Icon,
  SearchableTableHeader,
  Table,
  Typography,
} from '@fdha/web-ui-library';
import { NetworkStatus } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  TableCell,
  TableRow,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import {
  useDebouncedValue,
  useFilterBy,
  useSortBy,
  useTable,
} from '../../../../hooks';

const headCells: HeadCell<GroupRecipeBase>[] = [
  {
    id: 'coverImage',
    label: 'Recipe Image',
    sortable: false,
    searchable: false,
  },
  {
    id: 'name',
    label: 'Recipe Name',
    sortable: true,
    searchable: true,
  },
  {
    id: 'visibleToGroup',
    label: 'Visible to group',
    sortable: false,
    searchable: false,
  },
  {
    id: 'patientLink',
    label: 'Access link',
    sortable: false,
    searchable: false,
  },
];

interface RecipesTableProps {
  groupId: string;
  setRecipe: React.Dispatch<React.SetStateAction<string | null>>;
  onChangeGroupVisibility: (isVisible: boolean, id: string) => Promise<void>;
  onCopyLink: (recipeLink: string) => void;
}

const RecipesTable: FC<RecipesTableProps> = ({
  groupId,
  setRecipe,
  onChangeGroupVisibility,
  onCopyLink,
}) => {
  const theme = useTheme();
  const [visibleRecipe, setVisibleRecipe] = useState(true);
  const [hiddenRecipe, setHiddenRecipe] = useState(true);

  const [queryFilterBy, setQueryFilterBy] = useFilterBy<GroupRecipeBase>(
    'name',
    ''
  );
  const queryFilterByDebounced = useDebouncedValue(queryFilterBy);
  const [sortBy, setSortBy] = useSortBy<GroupRecipeBase>('name', 'asc');
  const {
    page,
    setPage,
    rowsPerPage,
    changeRowsPerPage,
    loading: loadingTablePreferences,
  } = useTable({
    key: 'groupRecipes',
  });

  const { data, loading, fetchMore, networkStatus } = useListGroupRecipesQuery({
    variables: {
      groupId,
      first: rowsPerPage,
      filterBy: {
        filterBy: [queryFilterByDebounced],
      },
      sortBy: {
        sortBy: [sortBy],
      },
      showVisible: visibleRecipe,
      showHidden: hiddenRecipe,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: loadingTablePreferences,
  });

  const recipes = data?.groupRecipes.edges.map((edge) => edge.node) || [];
  const totalNumberFound = data?.groupRecipes.totalNumberFound;
  const pageInfo = data?.groupRecipes.pageInfo;
  const emptyState = queryFilterByDebounced.value
    ? 'No results found'
    : 'No recipes';

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const renderRow = (row: GroupRecipeBase) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="RECIPE_IMAGE_CELL">
          <img
            src={row.coverImage || ''}
            alt="Recipe cover"
            width="180px"
            height="68px"
            style={{ objectFit: 'cover' }}
          />
        </TableCell>
        <TableCell data-testid="RECIPE_NAME_CELL">{row.name}</TableCell>
        <TableCell data-testid="RECIPE_VISIBLE_TO_GROUP_CELL">
          <Switch
            color="secondary"
            checked={row.visibleToGroup}
            onChange={() => onChangeGroupVisibility(row.visibleToGroup, row.id)}
          />
        </TableCell>
        <TableCell data-testid="ACCESS_LINK_CELL">
          <Button
            startIcon={
              <Icon
                name="copy-outline"
                size="medium"
                fill={theme.palette.text.secondary}
              />
            }
            onClick={() => onCopyLink(row.patientLink)}
          >
            <Typography
              variant="body2"
              textTransform="none"
              color={theme.palette.text.secondary}
            >
              Copy link
            </Typography>
          </Button>
        </TableCell>
        <TableCell>
          <IconButton
            data-testid="GROUP_RECIPE_BUTTON"
            onClick={() => setRecipe(row.id)}
          >
            <Icon
              size="large"
              name="arrow-ios-forward-outline"
              fill={grey[600]}
            />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" columnGap={3}>
        <SearchableTableHeader<GroupRecipeBase>
          headCells={headCells}
          defaultSearchField="name"
          onSearchChange={setQueryFilterBy}
          disableMargin
        />
        <Box display="flex" flexDirection="row" flex="none">
          <FormControlLabel
            label="Show visible recipes"
            checked={visibleRecipe}
            onChange={(_, value) => setVisibleRecipe(value)}
            control={<Checkbox />}
          />
          <FormControlLabel
            label="Show hidden recipes"
            checked={hiddenRecipe}
            onChange={(_, value) => setHiddenRecipe(value)}
            control={<Checkbox />}
          />
        </Box>
      </Box>
      <Paper data-testid="GROUP_RECIPES_TABLE" sx={{ marginTop: 2 }}>
        <Table<GroupRecipeBase>
          actions="right"
          initialOrderBy="name"
          headCells={headCells}
          renderRow={renderRow}
          rows={recipes}
          emptyState={emptyState}
          isLoading={loading}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={setSortBy}
          onPageChange={onPageChange}
          page={page}
          totalRowCount={totalNumberFound}
          withPagination
        />
      </Paper>
    </Box>
  );
};

export default RecipesTable;
