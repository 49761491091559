import React from 'react';
import { Loader, Table } from '@fdha/web-ui-library';
import {
  Paper as MuiPaper,
  styled,
  TableCell,
  TableRow,
  Box,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import {
  SurveyInstance,
  SurveyQuestion,
  SurveyQuestionType,
  useGetSurveyInstanceQuery,
} from '@fdha/graphql-api-admin';

import {
  getHeaderCells,
  getSurveyName,
  renderTableCells,
} from '../../../../utils/surveysData';

import SurveyQuestionView from './SurveyQuestionView';

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: '12px 24px',
  maxWidth: '857px',
  width: '100%',
  marginBottom: '24px',
  boxShadow: theme.shadows[2],
  alignSelf: 'center',
}));

const SurveyPreview = () => {
  const { patientId, surveyId } = useParams();
  const { data, loading } = useGetSurveyInstanceQuery({
    variables: {
      patientId: patientId || '',
      instanceId: surveyId || '',
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Loader />;
  }

  if (!data?.surveyInstance) {
    return <Typography>{`No survey found with ID ${surveyId}`}</Typography>;
  }

  const survey = {
    ...data.surveyInstance,
    name: getSurveyName(
      data.surveyInstance.name,
      data.surveyInstance.isStructured
    ),
  };

  const rows: SurveyInstance[] = [survey];

  const renderRow = (row?: SurveyInstance) => {
    return (
      <TableRow hover key={row?.name} data-testid="TABLE_ROW">
        <TableCell>
          <Link to="../" data-testid="SURVEYS_PREVIEW_BACK_BUTTON">
            <Icon
              name="arrow-ios-back-outline"
              fill={grey[600]}
              size="large"
              data-testid="SURVEYS_BUTTON"
            />
          </Link>
        </TableCell>
        {renderTableCells(row)}
      </TableRow>
    );
  };

  return (
    <>
      <MuiPaper
        data-testid="SURVEYS_PREVIEW_TABLE"
        sx={{ marginBottom: '56px' }}
      >
        <Table<SurveyInstance>
          actions="left"
          headCells={getHeaderCells(false)}
          initialOrderBy="name"
          renderRow={renderRow}
          rows={rows}
        />
      </MuiPaper>
      <Box
        display="flex"
        flexDirection="column"
        data-testid="SURVEY_PREVIEW_CONTAINER"
      >
        {data?.surveyInstance.questions
          ?.filter(
            (question) => question.type !== SurveyQuestionType.Explanation
          )
          .map((item: SurveyQuestion, index: number) => (
            <Paper data-testid="SURVEY_QUESTION_CONTAINER" key={item.id}>
              <SurveyQuestionView item={item} questionNumber={index} />
            </Paper>
          ))}
      </Box>
    </>
  );
};

export default SurveyPreview;
