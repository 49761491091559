import {
  CheckboxGroup,
  CheckboxGroupProps,
  DatePicker,
  DatePickerProps,
  RadioGroup,
  RadioGroupProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@fdha/web-ui-library';
import { Box, Divider } from '@mui/material';
import React, { HTMLInputTypeAttribute } from 'react';
import ReactQuill from 'react-quill';

import { RichTextEditor, ScalePicker } from '../index';
import { ScaleProps } from '../ScalePicker/ScalePicker';
import { RichTextEditorProps } from '../RichTextEditor/RichTextEditor';

interface QuestionFieldCommonProps {
  number: number;
  title: string;
  helperText?: string | null;
  enabled?: boolean;
  dataTestIdPrefix: string;
  enableRTE?: boolean;
}

type ConditionalProps =
  | { type: 'RTE'; rteProps: Omit<RichTextEditorProps, 'enabled'> }
  | {
      type: 'openText';
      textType?: HTMLInputTypeAttribute;
      textFieldProps: Omit<TextFieldProps, 'disabled'>;
    }
  | {
      type: 'multipleChoice';
      checkboxGroupProps: Omit<CheckboxGroupProps, 'disabled'>;
    }
  | {
      type: 'singleChoice';
      radioGroupProps: Omit<RadioGroupProps, 'disabled'>;
    }
  | {
      type: 'scale';
      scaleProps: Omit<ScaleProps, 'disabled'>;
    }
  | {
      type: 'date';
      datePickerProps: Omit<DatePickerProps, 'disabled'>;
    };

export type QuestionFieldProps = QuestionFieldCommonProps & ConditionalProps;

const QuestionField: React.FC<QuestionFieldProps> = ({
  number,
  title,
  helperText,
  enabled = false,
  enableRTE = false,
  dataTestIdPrefix,
  ...customProps
}) => {
  const disabled = !enabled;

  const getContent = () => {
    switch (customProps.type) {
      case 'RTE': {
        return <RichTextEditor {...customProps.rteProps} enabled={enabled} />;
      }
      case 'openText': {
        return (
          <TextField {...customProps.textFieldProps} disabled={disabled} />
        );
      }
      case 'multipleChoice': {
        return (
          <CheckboxGroup
            {...customProps.checkboxGroupProps}
            checkboxProps={{ disabled }}
          />
        );
      }
      case 'singleChoice': {
        return (
          <RadioGroup
            {...customProps.radioGroupProps}
            radioProps={{ disabled }}
          />
        );
      }
      case 'scale': {
        return <ScalePicker {...customProps.scaleProps} disabled={disabled} />;
      }
      case 'date': {
        return (
          <DatePicker disabled={disabled} {...customProps.datePickerProps} />
        );
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        paddingY="12px"
        data-testid={`${dataTestIdPrefix}_QUESTION_FIELD`}
      >
        <Typography variant="h6" whiteSpace="nowrap">
          {number}.
        </Typography>
        <Box marginLeft="12px">
          <Typography
            variant="h6"
            data-testid={`${dataTestIdPrefix}_QUESTION_FIELD_TITLE`}
          >
            {title}
          </Typography>
          {enableRTE ? (
            helperText && (
              <Box marginLeft="-12px">
                <ReactQuill
                  data-testid={`${dataTestIdPrefix}_QUESTION_FIELD_HELPER_TEXT`}
                  value={helperText}
                  theme="bubble"
                  readOnly
                />
              </Box>
            )
          ) : (
            <Typography
              variant="body1"
              data-testid={`${dataTestIdPrefix}_QUESTION_FIELD_HELPER_TEXT`}
            >
              {helperText}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider />
      <Box
        pt={3}
        pb="12px"
        data-testid={`${dataTestIdPrefix}_QUESTION_FIELD_CONTENT`}
      >
        {getContent()}
      </Box>
    </>
  );
};

export default QuestionField;
