import { SurveyQuestion, SurveyQuestionType } from '@fdha/graphql-api-admin';
import { CheckboxGroupState } from '@fdha/web-ui-library';
import React, { useMemo, useState } from 'react';

import { QuestionField } from '../../../../components';

interface SurveyQuestionViewProps {
  questionNumber: number;
  item: SurveyQuestion;
  error?: string;
  onAnswerChange?: (questionId: string, answer: string | string[]) => void;
}

const SurveyQuestionView: React.FC<SurveyQuestionViewProps> = ({
  questionNumber,
  item,
  error,
  onAnswerChange,
}) => {
  const [answer, setAnswer] = useState<string | string[]>(item.answer || '');

  const enabled = !!onAnswerChange;

  const possibleAnswers = useMemo(
    () =>
      item.possibleAnswers?.map((possibleAnswer) => ({
        label: possibleAnswer,
        value: possibleAnswer,
      })) ?? [],
    [item.possibleAnswers]
  );

  const singleAnswer =
    typeof answer === 'string' ? answer : item.answer?.[0] || '';
  const placeholder = item.placeholderText || '';

  const commonProps = {
    number: questionNumber + 1,
    title: item.title,
    helperText: item.helpText || undefined,
    dataTestIdPrefix: 'SURVEY',
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setAnswer(newValue);
    onAnswerChange?.(item.id, newValue);
  };

  const handleMultipleChoiceChange = (newValues: CheckboxGroupState) => {
    const selectedValues = Object.keys(newValues).filter(
      (key) => newValues[key]
    );
    setAnswer(selectedValues);
    onAnswerChange?.(item.id, selectedValues);
  };

  const handleSingleChoiceChange = (newValue: string) => {
    setAnswer(newValue);
    onAnswerChange?.(item.id, newValue);
  };

  const renderQuestion = () => {
    switch (item.type) {
      case SurveyQuestionType.OpenText:
      case SurveyQuestionType.Decimal:
      case SurveyQuestionType.Meal:
      case SurveyQuestionType.Time:
      case SurveyQuestionType.Number:
      case SurveyQuestionType.Snack: {
        const type =
          item.type !== SurveyQuestionType.Time ? item.type : undefined;
        const multiline = item.type === SurveyQuestionType.OpenText;

        return (
          <QuestionField
            type="openText"
            textFieldProps={{
              value: singleAnswer,
              type,
              multiline,
              placeholder,
              error: !!error,
              helperText: error,
              onChange: handleInputChange,
            }}
            enabled={!!onAnswerChange}
            {...commonProps}
          />
        );
      }
      case SurveyQuestionType.MultipleChoice: {
        const multipleChoiceAnswer =
          possibleAnswers?.reduce<CheckboxGroupState>((acc, { value }) => {
            acc[value] = Array.isArray(answer)
              ? answer.includes(value)
              : item.answer?.includes(value) ?? false;
            return acc;
          }, {}) ?? {};

        return (
          <QuestionField
            type="multipleChoice"
            checkboxGroupProps={{
              options: possibleAnswers,
              value: multipleChoiceAnswer,
              error,
              onChange: handleMultipleChoiceChange,
            }}
            enabled={enabled}
            {...commonProps}
          />
        );
      }
      case SurveyQuestionType.SingleChoice:
      case SurveyQuestionType.Binary:
        return (
          <QuestionField
            type="singleChoice"
            radioGroupProps={{
              options: possibleAnswers,
              value: singleAnswer,
              placeholder,
              error,
              onChange: handleSingleChoiceChange,
            }}
            enabled={enabled}
            {...commonProps}
          />
        );
      case SurveyQuestionType.Scale:
        return (
          <QuestionField
            type="scale"
            scaleProps={{
              options: possibleAnswers,
              value: singleAnswer,
              error,
              onChange: handleSingleChoiceChange,
            }}
            enabled={enabled}
            {...commonProps}
          />
        );
    }
  };

  return <>{renderQuestion()}</>;
};

export default SurveyQuestionView;
