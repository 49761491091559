import { Stack } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

import DataTable from './DataTable';
import BHBData from './BHBData';

const Data = () => {
  const params = useParams();
  const patientId = params.patientId || '';

  return (
    <Stack spacing={3}>
      <BHBData patientId={patientId} />
      <DataTable patientId={patientId} />
    </Stack>
  );
};

export default Data;
