import { Paper, TableCell, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import React, { useEffect } from 'react';
import {
  Button,
  useDialog,
  Table,
  RowsPerPageOptions,
} from '@fdha/web-ui-library';
import { NetworkStatus, useApolloClient } from '@apollo/client';
import {
  ListAllAssignedSurveysDocument,
  useGetPatientUserQuery,
  useListPublishedSurveysQuery,
} from '@fdha/graphql-api-admin';

import { AssignSurvey, BasePage } from '../../../../../components';
import { useSortBy, useTable, useGetUserType } from '../../../../../hooks';
import {
  getSurveyName,
  SurveyScheduleProps,
  headCellsForAssignableSurveys,
  AssignableSurveyProps,
} from '../../../../../utils';

const AssignableSurveysList = () => {
  const client = useApolloClient();
  const params = useParams();
  const { openDialog, closeDialog } = useDialog();
  const [, setSortBy] = useSortBy<SurveyScheduleProps>('name', 'asc');

  const patientId = params.patientId || '';

  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'assignable-schedules',
  });

  const { data: patientData } = useGetPatientUserQuery({
    variables: {
      id: patientId,
    },
  });

  const { isCsr } = useGetUserType();

  const patientName = patientData?.patientUser?.name;

  const { data, error, fetchMore, loading, networkStatus, refetch } =
    useListPublishedSurveysQuery({
      variables: { first: 100 },
      fetchPolicy: 'cache-and-network',
    });

  const nodes =
    data?.publishedSurveys.edges.map(({ node }) => ({
      name: getSurveyName(node.name, node.isStructured),
      id: node.id,
    })) ?? [];

  const pageInfo = data?.publishedSurveys.pageInfo;
  const totalNumberFound = data?.publishedSurveys.totalNumberFound;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          patientId: patientId,
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const onRowsPerPageChange = (rowsPerPage: RowsPerPageOptions) => {
    changeRowsPerPage(rowsPerPage);
  };

  const openAssignSurvey = (row: AssignableSurveyProps) => {
    openDialog({
      content: (
        <AssignSurvey
          mode="toPatient"
          action="assign"
          patientData={{ id: patientId, name: patientName || '' }}
          surveyData={{
            id: row.id,
            name: row.name,
            isAssigned: false,
          }}
          onSuccess={() => {
            refetch();
            client.refetchQueries({
              include: [ListAllAssignedSurveysDocument],
              updateCache(cache) {
                cache.evict({ fieldName: 'allAssignedSurveys' });
              },
            });
          }}
          onFinish={closeDialog}
        />
      ),
    });
  };

  const getButton = (row: AssignableSurveyProps) => {
    return (
      <Button
        data-testid="ASSIGN_BUTTON"
        color="secondary"
        variant="contained"
        startEvaIcon={{ name: 'plus-outline' }}
        onClick={() => openAssignSurvey(row)}
      >
        Assign
      </Button>
    );
  };

  const renderRow = (row: AssignableSurveyProps) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="SURVEY_SCHEDULE_NAME">{row.name}</TableCell>
        {!isCsr && <TableCell align="right">{getButton(row)}</TableCell>}
        <TableCell>
          <Link to={row.id} data-testid="SURVEY_SCHEDULE_DETAILS_BUTTON">
            <Icon
              name="arrow-ios-forward-outline"
              fill={grey[600]}
              size="large"
              data-testid="SCHEDULE_BUTTON"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <BasePage data-testid="SCHEDULES_CONTAINER">
      <Typography variant="h5">Surveys</Typography>
      <br />
      <Paper data-testid="SCHEDULES_TABLE">
        <Table<AssignableSurveyProps>
          actions={!isCsr ? 'right' : undefined}
          headCells={headCellsForAssignableSurveys}
          initialOrderBy="name"
          isLoading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          renderRow={renderRow}
          totalRowCount={totalNumberFound}
          rows={nodes}
          emptyState="No surveys to assign"
          withPagination
          onSortChange={setSortBy}
        />
      </Paper>
    </BasePage>
  );
};

export default AssignableSurveysList;
