import React, { useEffect, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { Paper, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import {
  TextField,
  Button,
  Table,
  RowsPerPageOptions,
  Select,
  DatePicker,
} from '@fdha/web-ui-library';
import {
  AssignmentType,
  FilterOp,
  SurveyResponse,
  SurveyStatus,
  useGetProfileQuery,
  useListAllSurveyResponsesQuery,
} from '@fdha/graphql-api-admin';

import {
  getSurveyResponsesHeaderCells,
  renderSurveyResponsesTableCells,
} from '../../../../utils';
import {
  useFilterBy,
  useSortBy,
  useDebouncedValue,
  useTable,
  useGetUserType,
} from '../../../../hooks';

import AvailableSurveys from './AvailableSurveys';

const SurveysTab = () => {
  const params = useParams();
  const [labelFilterBy, setLabelFilterBy] = useFilterBy<SurveyResponse>(
    'label',
    ''
  );
  const [surveyDateFilterBy, setSurveyDateFilterBy] =
    useFilterBy<SurveyResponse>('survey_date', '', FilterOp.Equal);
  const [assignmentTypeFilterBy, setAssignmentTypeFilterBy] =
    useFilterBy<SurveyResponse>('assignment_type', '');
  const [surveyStatusFilterBy, setSurveyStatusFilterBy] =
    useFilterBy<SurveyResponse>('survey_status', '');
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [sortBy, setSortBy] = useSortBy<SurveyResponse>('survey_date', 'desc');

  const [searchQuery, setSearchQuery] = useState('');

  const { isCsr } = useGetUserType();

  const labelFilterByDebounced = useDebouncedValue(labelFilterBy);
  const surveyDateFilterByDebounced = useDebouncedValue(surveyDateFilterBy);

  const patientId = params.patientId || '';

  const { data: profileData } = useGetProfileQuery();

  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'allSurveyResponses',
  });

  const headCells = getSurveyResponsesHeaderCells();

  const { data, error, fetchMore, loading, networkStatus } =
    useListAllSurveyResponsesQuery({
      variables: {
        patientId: patientId,
        first: rowsPerPage,
        filterBy: {
          filterBy: [
            labelFilterByDebounced,
            surveyDateFilterByDebounced,
            assignmentTypeFilterBy,
            surveyStatusFilterBy,
          ].filter((filter) => !!filter.value),
        },
        sortBy: {
          sortBy: [sortBy],
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  const nodes = data?.allSurveyResponses.edges.map((edge) => edge.node) || [];

  const pageInfo = data?.allSurveyResponses.pageInfo;
  const totalNumberFound = data?.allSurveyResponses.totalNumberFound;

  const emptyState =
    labelFilterByDebounced.value ||
    surveyDateFilterByDebounced.value ||
    assignmentTypeFilterBy.value ||
    surveyStatusFilterBy.value
      ? 'No results found'
      : 'No opened surveys';

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          patientId: patientId,
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const onRowsPerPageChange = (rowsPerPage: RowsPerPageOptions) => {
    changeRowsPerPage(rowsPerPage);
  };

  const renderRow = (row: SurveyResponse) => {
    const responseId = row.id.split('-')[1];
    const navigateTo =
      row.assignment_type === AssignmentType.Available
        ? `available/${responseId}`
        : responseId;
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        {renderSurveyResponsesTableCells(row, profileData?.me.id, patientId)}
        {!isCsr && (
          <TableCell>
            <Link to={navigateTo} data-testid="SURVEY_INSTANCE_DETAILS_BUTTON">
              <Icon
                name="arrow-ios-forward-outline"
                fill={grey[600]}
                size="large"
                data-testid="SURVEYS_BUTTON"
              />
            </Link>
          </TableCell>
        )}
      </TableRow>
    );
  };

  const surveyStatusOptions = [
    { label: 'All', value: '' },
    { label: 'Done', value: SurveyStatus.Done },
    { label: 'Missing', value: SurveyStatus.Late },
    { label: 'Open', value: SurveyStatus.Open },
  ];

  const assignmentTypeOptions = [
    { label: 'All', value: '' },
    { label: 'Required', value: AssignmentType.Required },
    { label: 'Available', value: AssignmentType.Available },
  ];

  return (
    <>
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginBottom: 3 }}
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h5">Opened Surveys</Typography>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            endEvaIcon={{ name: 'arrow-ios-forward-outline' }}
            sx={{
              height: '42px',
              padding: '8px 32px',
            }}
            component={Link}
            to="schedules"
            data-testid="SURVEY_SCHEDULES_BUTTON"
          >
            New Assignment
          </Button>
        </Stack>
        <Stack
          sx={{ marginBottom: 2 }}
          spacing={1}
          direction="row"
          justifyContent="space-between"
        >
          <TextField
            placeholder="Search for internal name"
            onChange={(event) => {
              setSearchQuery(event.target.value);
              setLabelFilterBy('label', event.target.value);
            }}
            value={searchQuery}
            autoFocus
            data-testid="SEARCH_INPUT"
            sx={{ flex: 1 }}
          />
          <div style={{ width: '220px' }}>
            <DatePicker
              onChange={(date) => {
                setDatePickerOpen(true);
                if (date && !isNaN(date.getTime())) {
                  setSurveyDateFilterBy(
                    'survey_date',
                    date?.toLocaleDateString() || '',
                    FilterOp.Equal
                  );
                }
              }}
              sx={{ width: '200px' }}
              label={datePickerOpen ? 'Survey Date' : 'Select Survey Date'}
              onOpen={() => setDatePickerOpen(true)}
            />
          </div>
          <Select
            options={assignmentTypeOptions}
            onChange={(event) => {
              setAssignmentTypeFilterBy(
                'assignment_type',
                event.target.value as AssignmentType
              );
            }}
            placeholder="Select type"
            sx={{ width: '160px' }}
            value={assignmentTypeFilterBy.value}
          />
          <Select
            options={surveyStatusOptions}
            onChange={(event) => {
              setSurveyStatusFilterBy(
                'survey_status',
                event.target.value as SurveyStatus
              );
            }}
            placeholder="Select status"
            sx={{ width: '160px' }}
            value={surveyStatusFilterBy.value}
          />
        </Stack>
        <Paper data-testid="SURVEY_RESPONSES_TABLE" sx={{ marginBottom: 4 }}>
          <Table<SurveyResponse>
            actions={!isCsr ? 'right' : undefined}
            headCells={headCells}
            isLoading={loading}
            initialOrderBy="survey_date"
            totalRowCount={totalNumberFound}
            renderRow={renderRow}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            rows={nodes}
            emptyState={emptyState}
            withPagination
            onSortChange={setSortBy}
          />
        </Paper>
      </>
      <AvailableSurveys />
    </>
  );
};

export default SurveysTab;
