import {
  Button,
  HeadCell,
  Loader,
  Table,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  Paper as MuiPaper,
  styled,
  TableCell,
  TableRow,
  Box,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { parseISO } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import { formatDate } from '@fdha/web-ui-library';
import {
  Survey,
  SurveyQuestion,
  SurveyQuestionType,
  useChangeSurveyNotifyOnCompletionMutation,
  useGetSurveyQuery,
} from '@fdha/graphql-api-admin';

import BasePage from '../../../../../components/BasePage/BasePage';
import AssignSurvey from '../../../../../components/AssignSurvey/AssignSurvey';
import { useGetUserType } from '../../../../../hooks';
import { getSurveyName } from '../../../../../utils';
import SurveyQuestionView from '../SurveyQuestionView';

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: '12px 24px',
  maxWidth: '857px',
  width: '100%',
  marginBottom: '24px',
  boxShadow: theme.shadows[2],
  alignSelf: 'center',
}));

const headCells: HeadCell<Survey>[] = [
  { id: 'name', label: 'Survey name', sortable: false },
  { id: 'created_at', label: 'Creation date', sortable: false },
];

interface SchedulesPreviewProps {
  backRoute?: string;
  isAssignEnabled?: boolean;
}

const SchedulesPreview: FC<SchedulesPreviewProps> = ({
  backRoute = '../',
  isAssignEnabled,
}) => {
  const { surveyId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { isCsr } = useGetUserType();

  const { data, loading } = useGetSurveyQuery({
    variables: {
      surveyId: surveyId || '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const [changeNotifyOnCompletion] =
    useChangeSurveyNotifyOnCompletionMutation();

  const row = data?.survey
    ? {
        ...data.survey,
        name: getSurveyName(data.survey.name, data.survey.isStructured),
      }
    : null;

  const [notifyOnCompletion, setNotifyOnCompletion] = useState(
    row?.notifyCompletion ?? false
  );

  useEffect(() => {
    if (row?.notifyCompletion != null) {
      setNotifyOnCompletion(row?.notifyCompletion);
    }
  }, [row?.notifyCompletion]);

  if (loading) {
    return <Loader />;
  }

  if (!row) {
    return <Typography>{`No survey found with ID ${surveyId}`}</Typography>;
  }

  const handleNotifyCompletionChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setNotifyOnCompletion(isChecked);

    try {
      await changeNotifyOnCompletion({
        variables: { surveyId: row.id, notifyOnCompletion: isChecked },
      });
      showSnackbar({
        severity: 'success',
        message: 'Changes saved!',
      });
      navigate(location.pathname, {
        state: { row: { ...row, notifyCompletion: isChecked } },
      });
    } catch (_e) {
      showSnackbar({
        severity: 'error',
        message: 'Error to update data',
      });
      setNotifyOnCompletion(!isChecked);
      navigate(location.pathname, {
        state: { row: { ...row, notifyCompletion: !isChecked } },
      });
    }
  };

  const handleAssign = () => {
    openDialog({
      title: '',
      content: (
        <AssignSurvey
          mode="toPatientsAndGroups"
          surveyData={{ id: row.id, name: row.name }}
          onFinish={closeDialog}
        />
      ),
    });
  };

  const renderRow = (row?: Survey) => {
    return (
      <TableRow hover key={row?.name} data-testid="TABLE_ROW">
        <TableCell>{row?.name}</TableCell>
        <TableCell>
          {row?.created_at && formatDate(parseISO(row.created_at))}
        </TableCell>
      </TableRow>
    );
  };

  const showAssignButton = isAssignEnabled && !row.archived;

  return (
    <BasePage data-testid="SCHEDULE_PREVIEW_CONTAINER">
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <BasePage.BackButton to={backRoute} />
        <Box>
          {!isCsr && (
            <FormControlLabel
              label="Receive Notification when Patient Completes"
              control={
                <Switch
                  color="secondary"
                  name="notifyCompletion"
                  checked={notifyOnCompletion}
                  onChange={handleNotifyCompletionChange}
                />
              }
              data-testid="NOTIFICATION_TOGGLE"
            />
          )}
          {showAssignButton && (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startEvaIcon={{ name: 'plus-outline' }}
              onClick={handleAssign}
              data-testid="ASSIGN_BUTTON"
            >
              assign
            </Button>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column">
        <MuiPaper
          data-testid="SCHEDULES_PREVIEW_TABLE"
          sx={{ marginBottom: '56px', maxWidth: '857px', width: '100%' }}
        >
          <Table<Survey>
            initialOrderBy="name"
            headCells={headCells}
            renderRow={renderRow}
            rows={[row]}
          />
        </MuiPaper>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth="857px"
        >
          {row?.questions
            .filter(
              (question) => question.type !== SurveyQuestionType.Explanation
            )
            .map((item: SurveyQuestion, index) => (
              <Paper key={item.id}>
                <SurveyQuestionView item={item} questionNumber={index} />
              </Paper>
            ))}
        </Box>
      </Box>
    </BasePage>
  );
};

export default SchedulesPreview;
