import React from 'react';
import {
  Box,
  Checkbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormGroup,
  Paper as MuiPaper,
  styled,
  useTheme,
} from '@mui/material';

import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';
import { Icon, IconProps } from '../Icon/Icon';
import { InputError } from '../InputError/InputError';

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: '2px 20px',
  marginBottom: '12px',
  border: '0.5px solid',
  borderColor: theme.palette.divider,
}));

export type CheckboxGroupState = { [key: string]: boolean };

export type CheckboxProps = Omit<MuiCheckboxProps, 'onChange' | 'checked'>;

export interface CheckboxOption {
  label: string;
  value: string;
  icon?: IconProps;
}

export interface CheckboxGroupProps {
  options: CheckboxOption[];
  value: CheckboxGroupState;
  onChange?: (value: CheckboxGroupState) => void;
  checkboxProps?: CheckboxProps;
  error?: string;
  v2?: boolean;
}

export const CheckboxGroup = ({
  options,
  value,
  onChange,
  checkboxProps = {},
  error,
  v2 = false,
}: CheckboxGroupProps) => {
  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = { ...value, [event.target.name]: event.target.checked };

    onChange?.(newState);
  };

  /************ V2 updates *********************/

  const checkboxStyle = v2 ? undefined : { mr: '12px' };
  const formLabelStyle = (selected: boolean) =>
    v2
      ? undefined
      : {
          color: selected ? theme.palette.primary.contrastText : undefined,
          '& .MuiFormControlLabel-label.Mui-disabled': {
            color: selected ? theme.palette.primary.contrastText : undefined,
          },
          '& .MuiCheckbox-root.Mui-checked': {
            color: selected ? theme.palette.primary.contrastText : undefined,
          },
          width: '100%',
          mr: 0,
        };

  /*********************************************/

  return (
    <FormGroup>
      {options.map((option) => {
        const checked = !!value[option.value];

        return (
          <ConditionalWrapper
            key={option.label}
            showWrapper={!v2}
            wrapper={(children) => (
              <Paper
                sx={{
                  backgroundColor: value[option.value]
                    ? theme.palette.primary.light
                    : undefined,
                }}
              >
                {children}
              </Paper>
            )}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              data-testid={`CHECKBOX_OPTION_${option.label}`}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={checkboxStyle}
                    name={option.value}
                    {...checkboxProps}
                    onChange={handleChange}
                    checked={checked}
                  />
                }
                label={option.label}
                sx={formLabelStyle(checked)}
              />
              {option.icon && <Icon {...option.icon} />}
            </Box>
          </ConditionalWrapper>
        );
      })}
      {error && <InputError error={error} v2={v2} />}
    </FormGroup>
  );
};
