import React, { FC } from 'react';
import { signIn } from '@aws-amplify/auth';
import { useNavigate, Link } from 'react-router-dom';
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikTouched,
} from 'formik';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Link as MuiLink,
  Stack,
} from '@mui/material';
import {
  Button,
  PasswordTextField,
  TextField,
  Typography,
  useSnackbar,
} from '@fdha/web-ui-library';
import * as Yup from 'yup';
import { Trans } from 'react-i18next';
import { getTranslatedErrorMessage } from '@fdha/common-utils';

import { setCognitoUser } from '../states/userCognitoState';
import { getUserId } from '../utils';

interface LoginSchema {
  username: string;
  password: string;
  rememberMe?: boolean;
}

export interface LoginProps {
  v2?: boolean;
  isV2UserPool?: boolean;
  handleRememberMe?: (value: boolean, userId: string) => void;
  onSignIn?: () => void;
}

const expiredPasswordHelper = () => {
  const contactLink = (
    <MuiLink
      href="mailto:support@faeththerapeutics.com?subject=Temporary Password Issue"
      target="_blank"
    >
      support@faeththerapeutics.com
    </MuiLink>
  );

  return (
    <span>
      <Trans
        i18nKey="login:snackbar.temporaryPasswordExpired"
        components={{ contactLink }}
      >
        Your temporary password has expired. <br />
        Please, contact Faeth at {contactLink} for support.
      </Trans>
    </span>
  );
};

export const Login: FC<LoginProps> = ({
  v2,
  isV2UserPool,
  handleRememberMe,
  onSignIn,
}) => {
  const { showSnackbarV2, showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const initialValues: LoginSchema = {
    username: '',
    password: '',
    rememberMe: false,
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .email(getTranslatedErrorMessage('validEmail', 'web'))
      .required(getTranslatedErrorMessage('requiredEmail', 'web')),
    password: Yup.string()
      .trim()
      .required(getTranslatedErrorMessage('requiredPassword', 'web')),
  });

  const authFlowType = isV2UserPool ? 'USER_PASSWORD_AUTH' : undefined;

  const handleSignIn = async (
    values: LoginSchema,
    { setFieldError }: FormikHelpers<LoginSchema>
  ) => {
    try {
      const { nextStep } = await signIn({
        username: values.username,
        password: values.password,
        options: {
          authFlowType,
        },
      });

      if (
        nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        setCognitoUser({
          username: values.username,
          challengeParam: {
            CODE_DELIVERY_DESTINATION: '',
          },
        });
        navigate('/change-password', {
          state: { username: values.username, rememberMe: values.rememberMe },
        });
      } else if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE') {
        const destination = nextStep.codeDeliveryDetails?.destination;

        if (!destination) throw new Error('Destination not found');

        setCognitoUser({
          username: values.username,
          challengeParam: {
            CODE_DELIVERY_DESTINATION: destination,
          },
        });
        navigate('/sms-challenge', {
          state: { username: values.username, password: values.password },
        });
      } else {
        if (handleRememberMe) {
          const userId = await getUserId();
          handleRememberMe(!!values.rememberMe, userId);
        }
        navigate('/', { replace: true });
        onSignIn && onSignIn();
      }
    } catch (err: any) {
      console.error(err);

      const isTempPasswordExpired =
        err.message ===
        'Temporary password has expired and must be reset by an administrator.';

      const errorMessage = isTempPasswordExpired
        ? expiredPasswordHelper()
        : err.message;

      if (v2 && isTempPasswordExpired) {
        setFieldError('password', errorMessage);
      } else if (v2) {
        const translatedMessage = [err.name, err.code].includes(
          'NotAuthorizedException'
        )
          ? {
              message: err.message,
              i18nKey: 'login:errors.notAuthorizedException',
            }
          : {
              message: 'Sorry, something went wrong. Please try again.',
              i18nKey: 'common:snackbar.somethingWentWrong',
            };

        showSnackbarV2({ ...translatedMessage, severity: 'error' });
      } else {
        showSnackbar({ message: errorMessage, severity: 'error' });
      }
    }
  };

  /************ V2 updates *********************/
  const emailPlaceholder = v2 ? '' : 'Your email...';
  const passwordPlaceholder = v2 ? '' : 'Your password...';
  const emailTitle = v2 ? 'Email' : 'Your email';
  const passwordTitle = v2 ? 'Password' : 'Your password';
  const buttonColor = v2 ? 'primary' : 'secondary';
  const buttonLabel = v2 ? 'Log In' : 'Log in';
  const forgotPasswordMargin = v2 ? 0.5 : 1;
  const forgotPasswordText = v2 ? 'Forgot password?' : 'Forgot your password?';

  const renderInputs = (
    values: LoginSchema,
    handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    touched: FormikTouched<LoginSchema>,
    errors: FormikErrors<LoginSchema>
  ) => {
    const usernameError = touched.username ? errors.username : null;
    const passwordError = touched.password ? errors.password : null;

    return (
      <Stack spacing={2}>
        <TextField
          v2={v2}
          i18nKeyTitle="login:input.email"
          name="username"
          title={emailTitle}
          placeholder={emailPlaceholder}
          value={values.username}
          onBlur={handleBlur}
          onChange={handleChange}
          type="email"
          error={!!usernameError}
          helperText={usernameError}
        />
        <PasswordTextField
          v2={v2}
          i18nKeyTitle="login:input.password"
          name="password"
          title={passwordTitle}
          placeholder={passwordPlaceholder}
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
          error={!!passwordError}
          helperText={passwordError}
        />
      </Stack>
    );
  };
  /*********************************************/

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSignIn}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        errors,
      }) => {
        return (
          <Form style={{ width: '100%' }} data-testId="LOGIN_FORM">
            <Box display="flex" flexDirection="column">
              {renderInputs(values, handleBlur, handleChange, touched, errors)}
            </Box>
            <Box mt={forgotPasswordMargin}>
              <MuiLink component={Link} to="/forgot-password">
                <Typography i18nKey="login:forgotPasswordLink">
                  {forgotPasswordText}
                </Typography>
              </MuiLink>
            </Box>
            {handleRememberMe && (
              <FormControlLabel
                sx={{ mt: 3 }}
                control={<Checkbox color="secondary" />}
                name="rememberMe"
                checked={values.rememberMe}
                onChange={handleChange}
                label={
                  <Typography i18nKey="login:keepMeLoggedIn" variant="inherit">
                    Keep me logged in
                  </Typography>
                }
              />
            )}
            <Button
              fullWidth
              i18nKey="login:loginButton"
              type="submit"
              variant="contained"
              color={buttonColor}
              disabled={isSubmitting}
              size="large"
              sx={{ height: 42, mt: 3 }}
              data-testid="LOGIN_BUTTON"
            >
              {buttonLabel}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
