import React from 'react';
import {
  Button,
  DatePicker,
  Loader,
  Table,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import {
  Paper as MuiPaper,
  TableCell,
  TableRow,
  Box,
  Typography,
  Divider,
  Stack,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import {
  AssignmentType,
  SurveyQuestion,
  SurveyQuestionAnswerInput,
  SurveyQuestionType,
  SurveyResponse,
  SurveyStatus,
  useAnswerAnytimeSurveyForSubjectMutation,
  useGetAnytimeSurveyAssignmentQuery,
} from '@fdha/graphql-api-admin';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  getSurveyResponsesHeaderCells,
  renderSurveyResponsesTableCells,
} from '../../../../utils';

import SurveyQuestionView from './SurveyQuestionView';
import { Paper } from './AnytimeSurveyPreview';

interface AnswerSurveyProps {
  showDatePicker?: boolean;
}

interface AnswerSurveyFormValues {
  selectedDate: Date;
  answers: SurveyQuestionAnswerInput[];
}

const initialValues: AnswerSurveyFormValues = {
  selectedDate: new Date(),
  answers: [],
};

const AnswerAnytimeSurvey: React.FC<AnswerSurveyProps> = ({
  showDatePicker = true,
}) => {
  const { patientId, assignmentId } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const { data, loading } = useGetAnytimeSurveyAssignmentQuery({
    variables: {
      assignmentId: assignmentId || '',
    },
    fetchPolicy: 'cache-and-network',
    skip: !assignmentId,
  });

  const [answerAnytimeSurveyMutation] =
    useAnswerAnytimeSurveyForSubjectMutation();

  const validationSchema = Yup.object({
    selectedDate: Yup.date().required('Date is required'),
    answers: Yup.array().test(
      'required-questions',
      'All required questions must be answered',
      (value) => {
        const requiredQuestions =
          data?.anytimeSurveyAssignment.questions.filter(
            (question) => question.required
          );

        return (
          requiredQuestions?.every(
            (question) =>
              value &&
              value.some(
                (answer) =>
                  answer.questionId === question.id && answer.answer.length > 0
              )
          ) || false
        );
      }
    ),
  });

  const onSubmit = async (values: AnswerSurveyFormValues) => {
    const { selectedDate, answers } = values;

    try {
      const variables = {
        patientId: patientId || '',
        surveyId: survey.surveyId,
        answers,
        respondedAt: selectedDate.toISOString(),
        respondedAtTz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      const result = await answerAnytimeSurveyMutation({ variables });
      const responseId = result.data?.answerAnytimeSurveyForSubject;
      navigate(`../${responseId}`);
    } catch (error) {
      showSnackbar({
        severity: 'error',
        message: 'Error when submitting survey',
      });
      console.error('Error submitting survey:', error);
      closeDialog();
    }
  };

  const {
    errors,
    values,
    isSubmitting,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  if (loading) {
    return <Loader />;
  }

  if (!data?.anytimeSurveyAssignment) {
    return (
      <Typography>{`No survey found with assignment ID ${assignmentId}`}</Typography>
    );
  }

  const survey = data.anytimeSurveyAssignment;

  const label = `${survey.name} ${survey.label ? '- ' + survey.label : ''}`;

  const rows: SurveyResponse[] = [
    {
      id: survey.id,
      label,
      assignment_type: AssignmentType.Available,
      survey_date: '',
      survey_status: SurveyStatus.Open,
      completed_at: '',
    },
  ];

  const renderRow = (row?: SurveyResponse) => {
    return (
      <TableRow hover key={row?.id} data-testid="TABLE_ROW">
        <TableCell>
          <Link to="../../" data-testid="SURVEYS_ANSWER_BACK_BUTTON">
            <Icon
              name="arrow-ios-back-outline"
              fill={grey[600]}
              size="large"
              data-testid="SURVEYS_BUTTON"
            />
          </Link>
        </TableCell>
        {renderSurveyResponsesTableCells(row)}
      </TableRow>
    );
  };

  const handleCloseAndDiscard = () => {
    openDialog({
      title: 'Leave without saving?',
      content:
        'Are you sure you want to leave this page? You have unsaved changes that will be lost',
      confirmButtonLabel: 'Leave without saving',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        navigate('../../');
        closeDialog();
      },
    });
  };

  const handleAnswerChange = (
    questionId: string,
    answer: string | string[]
  ) => {
    setFieldValue('answers', [
      ...values.answers.filter(
        (ans: SurveyQuestionAnswerInput) => ans.questionId !== questionId
      ),
      {
        questionId,
        answer: Array.isArray(answer)
          ? answer.filter((ans) => ans !== '')
          : answer !== ''
          ? [answer]
          : [],
      },
    ]);
  };

  const renderQuestion = (item: SurveyQuestion, index: number) => {
    const error =
      !!errors.answers && survey.questions?.[index].required && touched.answers;

    return (
      <Paper data-testid="SURVEY_QUESTION_CONTAINER" key={item.id}>
        <SurveyQuestionView
          item={item}
          questionNumber={index}
          onAnswerChange={handleAnswerChange}
          error={error ? 'Required questions cannot be empty' : undefined}
        />
      </Paper>
    );
  };

  return (
    <>
      <MuiPaper data-testid="SURVEYS_ANSWER_TABLE" sx={{ marginBottom: 7 }}>
        <Table<SurveyResponse>
          actions="left"
          headCells={getSurveyResponsesHeaderCells()}
          initialOrderBy="id"
          renderRow={renderRow}
          rows={rows}
        />
      </MuiPaper>
      <Box
        display="flex"
        flexDirection="column"
        data-testid="SURVEY_ANSWER_CONTAINER"
      >
        {showDatePicker && (
          <Paper sx={{ p: 4 }}>
            <Typography variant="h6" mb={1}>
              Survey date
            </Typography>
            <Stack spacing={3}>
              <Typography variant="body1">
                Select the date on which the subject completed this survey
              </Typography>
              <Divider />
              <DatePicker
                value={values.selectedDate}
                onChange={(date) => date && setFieldValue('selectedDate', date)}
              />
            </Stack>
          </Paper>
        )}
        {data?.anytimeSurveyAssignment.questions
          ?.filter(
            (question) => question.type !== SurveyQuestionType.Explanation
          )
          .map((item: SurveyQuestion, index: number) =>
            renderQuestion(item, index)
          )}
        <Box
          display="flex"
          gap={2}
          maxWidth="857px"
          width="100%"
          alignSelf="center"
        >
          <Button
            variant="contained"
            color="secondary"
            startEvaIcon={{
              name: 'save-outline',
              size: 'large',
              fill: theme.palette.primary.main,
            }}
            disabled={!!errors.selectedDate || isSubmitting}
            onClick={() => {
              handleSubmit();
              setFieldTouched('answers');
            }}
          >
            Save Response
          </Button>
          <Button
            variant="contained"
            startEvaIcon={{
              name: 'close-outline',
              size: 'large',
              fill: theme.palette.primary.contrastText,
            }}
            onClick={handleCloseAndDiscard}
          >
            Close and Discard Changes
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AnswerAnytimeSurvey;
