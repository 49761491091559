import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGetCoachUserQuery } from '@fdha/graphql-api-admin';

import { BasePage, UserIdentifier } from '../../../components';

import { AddOrEditCoachSiteTrial } from './AddOrEditCoachSiteTrial';

const AddCoachSiteTrial = () => {
  const navigate = useNavigate();
  const params = useParams();

  const coachId = params.coachId || '';

  const { data, loading } = useGetCoachUserQuery({
    variables: {
      id: coachId,
    },
  });

  return (
    <BasePage>
      <BasePage.BackButton />
      <BasePage.Wrapper>
        <BasePage.Title>
          <>
            Assign Site/Trial to Coach
            <UserIdentifier
              coachName={data?.coachUser?.name}
              showSkeleton={loading}
            />
          </>
        </BasePage.Title>
        <AddOrEditCoachSiteTrial
          onCancel={() => navigate(-1)}
          onSuccess={(coachId) => navigate(`/coach/${coachId}`)}
          coachId={coachId}
        />
      </BasePage.Wrapper>
    </BasePage>
  );
};

export default AddCoachSiteTrial;
