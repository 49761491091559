import {
  Box,
  FormControlLabel,
  InputAdornment,
  Paper,
  styled,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import Icon from 'react-eva-icons';
import {
  AssignmentType,
  useGetFoodProgramUserQuery,
} from '@fdha/graphql-api-admin';
import { getConcatWeekdaysOnDiet } from '@fdha/common-utils';
import { useFormikContext } from 'formik';

import SchedulesFormPreferences from './SchedulesFormPreferences';
import { AssignSurveySchema } from './AssignSurvey';
const NamePaper = styled(Paper)(({ theme }) => ({
  padding: '16px',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing(2),
}));

const IconPaper = styled(Paper)(() => ({
  padding: '8px',
  borderRadius: '7px',
  marginRight: '16px',
  lineHeight: 0,
}));

interface ScheduleProps {
  surveyName: string;
  title?: string;
  isBulkAssign: boolean;
  isDataSurvey?: boolean;
  patientId?: string;
  action?: 'assign' | 'edit';
  assignmentType?: AssignmentType | null;
}

const Schedule: React.FC<ScheduleProps> = ({
  surveyName,
  title,
  isDataSurvey,
  isBulkAssign,
  patientId,
  action,
  assignmentType,
}) => {
  const { values, setFieldValue } = useFormikContext<AssignSurveySchema>();
  const theme = useTheme();

  const { data: foodProgramData } = useGetFoodProgramUserQuery({
    variables: {
      patientId: patientId || '',
    },
    fetchPolicy: 'cache-and-network',
    skip: !patientId || isBulkAssign,
  });

  const foodProgramUser = foodProgramData?.foodProgramUserByPatientIdV2;

  const weekDaysOnDiet = foodProgramUser?.userDeliveryMenus?.length
    ? getConcatWeekdaysOnDiet(foodProgramUser?.userDeliveryMenus)
    : [];

  return (
    <>
      <Typography variant="h5">{title || 'Schedule Survey'}</Typography>
      <NamePaper>
        <IconPaper>
          <Icon
            name={isDataSurvey ? 'activity-outline' : 'file-text-outline'}
            fill={theme.palette.primary.main}
            size="large"
          />
        </IconPaper>
        <Typography variant="body1">{surveyName}</Typography>
      </NamePaper>

      <Stack spacing={1} sx={{ my: 3 }}>
        <Typography variant="subtitle2">Internal Name</Typography>
        <TextField
          fullWidth
          name="internalNameSuffix"
          placeholder="Enter internal name"
          value={values.internalNameSuffix}
          onChange={(e) => setFieldValue('internalNameSuffix', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ opacity: 0.7, pointerEvents: 'none' }}
              >
                {`${surveyName} - `}
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {action === 'assign' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h6">Schedule</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={values.disableSchedule}
                onChange={(e) => {
                  setFieldValue('disableSchedule', e.target.checked);
                }}
              />
            }
            label="Disable schedule (Available survey)"
          />
        </Box>
      )}

      {((action === 'assign' && !values.disableSchedule) ||
        assignmentType === AssignmentType.Required) && (
        <SchedulesFormPreferences>
          <SchedulesFormPreferences.StartDate />
          <SchedulesFormPreferences.EndsAt />
          <SchedulesFormPreferences.Time />
          <SchedulesFormPreferences.FrequencyForm
            weekDaysOnDiet={weekDaysOnDiet}
            isBulkAssign={isBulkAssign}
          />
          <SchedulesFormPreferences.CompleteWithin
            disabled={isDataSurvey}
            title={isDataSurvey ? 'Close after' : undefined}
          />
        </SchedulesFormPreferences>
      )}
    </>
  );
};

export default Schedule;
