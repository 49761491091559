import { InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { getToken } from '@fdha/common-utils';
import { createUploadLink } from 'apollo-upload-client';
import fdhaConfig from '@fdha/aws-config-admin';

const httpLink = createUploadLink({
  uri: fdhaConfig.api.adminApiBaseUrl,
  headers: {
    'x-api-key': fdhaConfig.api.apiKey,
    'apollo-require-preflight': 'true',
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

export const apolloClientConfig = {
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Goal: {
        keyFields: ['instanceId'],
      },
      Task: {
        keyFields: ['instanceId'],
      },
      Appointment: {
        keyFields: ['instanceId'],
      },
      GoalStep: {
        keyFields: false,
      },
      SurveyQuestion: {
        keyFields: false,
      },
      NoteQuestion: {
        keyFields: false,
      },
      ChartQuestion: {
        keyFields: false,
      },
      AutomationItem: {
        keyFields: ['type', 'patientId'],
        fields: {
          patientId: {
            read(existing, options) {
              return options?.variables?.patientId ?? existing;
            },
          },
        },
      },
      AutomationRequirement: {
        keyFields: ['type', 'patientId'],
        fields: {
          patientId: {
            read(existing, options) {
              return options?.variables?.patientId ?? existing;
            },
          },
        },
      },
      Query: {
        fields: {
          allPatients: relayStylePagination(),
          myPatients: relayStylePagination(),
          patientsBySite: relayStylePagination(),
          siteStaffBySite: relayStylePagination(),
          patientsByTrial: relayStylePagination(),
          surveySchedules: relayStylePagination(),
          surveys: relayStylePagination(),
          notes: relayStylePagination(),
          surveyInstances: relayStylePagination(),
          allSurveyResponses: relayStylePagination(),
          anytimeSurveyAssignments: relayStylePagination(),
          ctPresignupsV2: relayStylePagination(),
          coachUsers: relayStylePagination(),
          courses: relayStylePagination(),
          groups: relayStylePagination(),
          groupParticipants: relayStylePagination(),
          foodopsUsers: relayStylePagination(),
          siteStaffUsers: relayStylePagination(),
          sites: relayStylePagination(),
          patientsByCoach: relayStylePagination(),
          learningModules: relayStylePagination(),
          getPatientBhbAnswers: relayStylePagination(),
          documents: relayStylePagination(['ownerFilter']),
          groupRecipes: relayStylePagination(),
          notesTemplates: relayStylePagination(),
          coachSiteTrials: relayStylePagination(),
          allAssignedSurveys: relayStylePagination(),
          publishedSurveys: relayStylePagination(),
        },
      },
    },
  }),
};
