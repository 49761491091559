import React, { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import Icon from 'react-eva-icons';
import shortid from 'shortid';

import { QuestionCardType } from '../../../models/FormBuilderProps';
import DragQuestionCard from '../DragQuestionCard';
import {
  removeQuestion,
  setQuestionValue,
  duplicateQuestion,
} from '../../../states/questionBuilderState';

import PreviewQuestionContent from './PreviewQuestionContent';

export interface PreviewQuestionCardProps {
  item: QuestionCardType;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  enableRTE: boolean;
}

interface CardIconProps {
  handleClick: () => void;
  name: string;
  testId: string;
}

const PreviewQuestionCard: FC<PreviewQuestionCardProps> = ({
  item,
  index,
  moveCard,
  enableRTE = false,
}) => {
  const editQuestion = () => {
    const newValues = { ...item, isEditing: true };
    setQuestionValue(newValues);
  };

  const handleDuplicateQuestion = () => {
    const newValues = {
      ...item,
      id: shortid.generate(),
      title: `Copy of ${item.title}`,
      isEditing: true,
    };
    duplicateQuestion(newValues, index + 1);
  };

  const CardIcon = ({ handleClick, name, testId }: CardIconProps) => {
    return (
      <IconButton onClick={handleClick} data-testid={testId}>
        <Icon name={name} size="large" fill={grey[600]} />
      </IconButton>
    );
  };

  return (
    <DragQuestionCard item={item} index={index} moveCard={moveCard}>
      <PreviewQuestionContent item={item} index={index} enableRTE={enableRTE} />
      <Box display="flex" justifyContent="flex-end">
        <CardIcon
          handleClick={editQuestion}
          name="edit-outline"
          testId="EDIT_BUTTON"
        />
        <CardIcon
          handleClick={handleDuplicateQuestion}
          name="copy-outline"
          testId="DUPLICATE_BUTTON"
        />
        <CardIcon
          handleClick={() => removeQuestion(index)}
          name="trash-2-outline"
          testId="DELETE_BUTTON"
        />
      </Box>
    </DragQuestionCard>
  );
};

export default PreviewQuestionCard;
