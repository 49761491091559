import React from 'react';
import { formatDate, HeadCell, theme } from '@fdha/web-ui-library';
import { TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { Button } from '@fdha/web-ui-library';
import { AnytimeSurveyAssignmentExtended } from '@fdha/graphql-api-admin';

export const getAvailableSurveysHeaderCells =
  (): HeadCell<AnytimeSurveyAssignmentExtended>[] => {
    const headCells: HeadCell<AnytimeSurveyAssignmentExtended>[] = [
      {
        id: 'label',
        label: 'Internal name',
        sortable: false,
        searchable: false,
      },
      {
        id: 'createdAt',
        label: 'Assigned at',
        sortable: false,
        searchable: false,
      },
      {
        id: 'id',
        label: '',
        sortable: false,
        searchable: false,
      },
    ];

    return headCells;
  };

const getButton = (row: AnytimeSurveyAssignmentExtended) => {
  const assignmentId = row.id;
  return (
    <Button
      variant="contained"
      color="primary"
      startEvaIcon={{
        name: 'edit-2-outline',
        fill: theme.palette.primary.contrastText,
      }}
      component={Link}
      to={`available/answer/${assignmentId}`}
      data-testid="SURVEY_SCHEDULES_BUTTON"
      size="medium"
    >
      Add response
    </Button>
  );
};

export const renderAvailableSurveysTableCells = (
  row: AnytimeSurveyAssignmentExtended
): JSX.Element => {
  const createdAt = row.createdAt ? formatDate(new Date(row.createdAt)) : null;

  return (
    <TableRow hover key={row.id} data-testid="TABLE_ROW">
      <TableCell data-testid="AVAILABLE_SURVEYS_INTERNAL_NAME_CELL">
        {row.name} {row.label ? `- ${row.label}` : ''}
      </TableCell>
      <TableCell data-testid="AVAILABLE_SURVEYS_CREATED_AT_CELL">
        {createdAt ?? ''}
      </TableCell>
      <TableCell align="right">{row && getButton(row)}</TableCell>
    </TableRow>
  );
};
