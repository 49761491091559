"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.studyDietIconByValue = exports.deliveryShipmentStatusIconByValue = void 0;
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
exports.deliveryShipmentStatusIconByValue = (_a = {},
    _a[graphql_api_admin_1.TrackingStatus.BeingPrepared] = 'clock-outline',
    _a[graphql_api_admin_1.TrackingStatus.Delivered] = 'checkmark-outline',
    _a[graphql_api_admin_1.TrackingStatus.Failure] = 'alert-circle-outline',
    _a[graphql_api_admin_1.TrackingStatus.Returned] = 'alert-circle-outline',
    _a[graphql_api_admin_1.TrackingStatus.Transit] = 'car-outline',
    _a[graphql_api_admin_1.TrackingStatus.Unknown] = 'alert-circle-outline',
    _a);
exports.studyDietIconByValue = (_b = {},
    _b[graphql_api_admin_1.StudyDietOptions.None] = 'close-circle-outline',
    _b[graphql_api_admin_1.StudyDietOptions.Some] = 'minus-circle-outline',
    _b[graphql_api_admin_1.StudyDietOptions.Most] = 'checkmark-circle-2-outline',
    _b[graphql_api_admin_1.StudyDietOptions.All] = 'done-all-outline',
    _b);
