import React, { useMemo } from 'react';
import { isValid, parseISO } from 'date-fns';
import { useFormikContext } from 'formik';
import { CheckboxGroupState } from '@fdha/web-ui-library';
import {
  FormBuilderQuestionType,
  NoteQuestion,
  OpenTextQuestion,
  NumberQuestion,
  ChoicesQuestion,
} from '@fdha/graphql-api-admin';
import { Box } from '@mui/material';

import { getError } from '../../../../utils';
import { QuestionField } from '../../../../components';

import type { ChartForm } from './ChartCard';

interface ChartQuestionProps {
  question: NoteQuestion;
  index: number;
  preview?: boolean;
}

const ChartQuestionField = ({
  index,
  question,
  preview = false,
}: ChartQuestionProps) => {
  const { setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext<ChartForm>();

  const { type, customProps, answer, helpText } = question;
  const singleAnswer = answer?.[0] || '';

  const commonQuestionProps = {
    number: index + 1,
    title: question.title,
    dataTestIdPrefix: 'CHART',
    enabled: !preview,
    helperText: helpText,
    enableRTE: true,
  };

  const error = useMemo(
    () => getError(`questions.${index}.answer`, errors, touched),
    [errors, touched, index]
  );

  const handleChange = (value: string | string[]) => {
    const valueToSet = Array.isArray(value) ? value : [value];

    setFieldValue(`questions.${index}.answer`, valueToSet);
  };

  const handleBlur = () => {
    setFieldTouched(`questions.${index}.answer`, true);
  };

  const getChoicesOption = (possibleAnswers?: string[]) => {
    const options =
      possibleAnswers?.map((value) => ({
        label: value,
        value,
      })) ?? [];

    return options;
  };

  const getContent = () => {
    switch (type) {
      case FormBuilderQuestionType.OpenText: {
        const props = customProps as OpenTextQuestion | undefined;
        const { placeholderText } = props || {};

        return (
          <QuestionField
            type="RTE"
            rteProps={{
              placeholder: placeholderText || '',
              value: singleAnswer,
              onChange: handleChange,
            }}
            {...commonQuestionProps}
          />
        );
      }
      case FormBuilderQuestionType.Number: {
        const props = customProps as NumberQuestion | undefined;
        const { placeholderText } = props || {};

        return (
          <QuestionField
            type="openText"
            textFieldProps={{
              value: singleAnswer,
              placeholder: placeholderText,
              onChange: (event) => handleChange(event.target.value),
            }}
            {...commonQuestionProps}
          />
        );
      }
      case FormBuilderQuestionType.MultipleChoice: {
        const props = customProps as ChoicesQuestion | undefined;
        const { possibleAnswers } = props || {};

        const value =
          possibleAnswers?.reduce<CheckboxGroupState>((acc, possibleAnswer) => {
            acc[possibleAnswer] = answer?.includes(possibleAnswer) ?? false;
            return acc;
          }, {}) ?? {};

        const handleCheckboxChange = (newValue: CheckboxGroupState) => {
          const answers = Object.entries(newValue)
            .filter(([_option, checked]) => checked)
            .map(([option]) => option);

          handleChange(answers);
        };

        return (
          <QuestionField
            type="multipleChoice"
            checkboxGroupProps={{
              options: getChoicesOption(possibleAnswers),
              value,
              onChange: handleCheckboxChange,
            }}
            {...commonQuestionProps}
          />
        );
      }
      case FormBuilderQuestionType.Binary:
      case FormBuilderQuestionType.SingleChoice: {
        const props = customProps as ChoicesQuestion | undefined;
        const { possibleAnswers } = props || {};

        return (
          <QuestionField
            type="singleChoice"
            radioGroupProps={{
              options: getChoicesOption(possibleAnswers),
              value: singleAnswer,
              onChange: handleChange,
            }}
            {...commonQuestionProps}
          />
        );
      }
      case FormBuilderQuestionType.Scale: {
        const props = customProps as ChoicesQuestion | undefined;
        const { possibleAnswers } = props || {};

        return (
          <QuestionField
            type="scale"
            scaleProps={{
              value: singleAnswer,
              options: getChoicesOption(possibleAnswers),
              onChange: handleChange,
            }}
            {...commonQuestionProps}
          />
        );
      }
      case FormBuilderQuestionType.Date: {
        const value = singleAnswer ? parseISO(singleAnswer) : null;

        const handleDateChange = (date: Date | null) => {
          let newValue = '';
          if (date) {
            if (isValid(date)) {
              newValue = date.toISOString();
            } else {
              newValue = date.toString();
            }
          }

          handleChange(newValue);
        };

        return (
          <QuestionField
            type="date"
            datePickerProps={{
              value,
              onChange: handleDateChange,
              onBlur: handleBlur,
              error: !!error,
              helperText: error,
            }}
            {...commonQuestionProps}
          />
        );
      }
    }
  };

  return <Box>{getContent()}</Box>;
};

export default ChartQuestionField;
