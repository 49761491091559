"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrialStatus = exports.TrackingStatus = exports.SurveyStatus = exports.SurveyQuestionType = exports.SurveyFrequency = exports.SubjectStatus = exports.StudyDietOptions = exports.SortByOrder = exports.SiteStatus = exports.SiteStaffRole = exports.ShipmentLabelStatus = exports.ScheduledItemType = exports.RegistrationStatus = exports.RecipeType = exports.PlatformType = exports.PhysicalActivityLevel = exports.NotificationTypeV2 = exports.NotificationType = exports.MenuType = exports.MealType = exports.MainGoalType = exports.LogicCondition = exports.LogicAction = exports.FormBuilderQuestionType = exports.FoodProgramStatus = exports.FilterOp = exports.FeatureFlagType = exports.DraftFillingStatus = exports.DocumentOwnerFilter = exports.DocumentOverwriteOption = exports.DishType = exports.DeviceNotificationChannel = exports.DeliveryStatus = exports.DeliverySettings = exports.DeliveryKey = exports.DateQuestionInitialState = exports.CustomFieldName = exports.CourseAssignmentTypes = exports.ContentExplanationType = exports.CoachPrefType = exports.AssignmentType = exports.AssignType = exports.AppointmentLocation = exports.AppointmentDuration = exports.AddressValidationStatus = exports.ActivityReminder = exports.ActivityKind = exports.ActivityFrequency = exports.ActivitiesPaginationMode = exports.AccessType = void 0;
exports.GetSupportedLanguagesDocument = exports.GetSiteTrialDocument = exports.GetCustomFieldsValidationDocument = exports.GetOnboardingCallInfoDocument = exports.GetScheduledOnboardingCallDocument = exports.GetInfoForAnalyticsDocument = exports.GetAvailableTimesForOnboardingCallDocument = exports.GetAvailableDatesForOnboardingCallDocument = exports.GetSubjectDraftDocument = exports.GetEnvironmentNameDocument = exports.ListTrialSubjectsDocument = exports.GetTrialDocument = exports.GetSiteTrialsFromSiteStaffUserDocument = exports.GetProfileDocument = exports.GetPatientUserDocument = exports.UpdateUserSessionInfoDocument = exports.ResendOneTimePasswordDocument = exports.ScheduleOnboardingCallDocument = exports.CancelPatientAcuityAppointmentDocument = exports.EditSubjectDraftDocument = exports.DeleteSubjectDraftDocument = exports.SaveSubjectDraftDocument = exports.UpdateSubjectDocument = exports.AddSubjectDocument = exports.WeekDay = exports.ValidationType = exports.UserType = exports.UserGender = void 0;
exports.useAddSubjectMutation = useAddSubjectMutation;
exports.useUpdateSubjectMutation = useUpdateSubjectMutation;
exports.useSaveSubjectDraftMutation = useSaveSubjectDraftMutation;
exports.useDeleteSubjectDraftMutation = useDeleteSubjectDraftMutation;
exports.useEditSubjectDraftMutation = useEditSubjectDraftMutation;
exports.useCancelPatientAcuityAppointmentMutation = useCancelPatientAcuityAppointmentMutation;
exports.useScheduleOnboardingCallMutation = useScheduleOnboardingCallMutation;
exports.useResendOneTimePasswordMutation = useResendOneTimePasswordMutation;
exports.useUpdateUserSessionInfoMutation = useUpdateUserSessionInfoMutation;
exports.useGetPatientUserQuery = useGetPatientUserQuery;
exports.useGetPatientUserLazyQuery = useGetPatientUserLazyQuery;
exports.useGetProfileQuery = useGetProfileQuery;
exports.useGetProfileLazyQuery = useGetProfileLazyQuery;
exports.useGetSiteTrialsFromSiteStaffUserQuery = useGetSiteTrialsFromSiteStaffUserQuery;
exports.useGetSiteTrialsFromSiteStaffUserLazyQuery = useGetSiteTrialsFromSiteStaffUserLazyQuery;
exports.useGetTrialQuery = useGetTrialQuery;
exports.useGetTrialLazyQuery = useGetTrialLazyQuery;
exports.useListTrialSubjectsQuery = useListTrialSubjectsQuery;
exports.useListTrialSubjectsLazyQuery = useListTrialSubjectsLazyQuery;
exports.useGetEnvironmentNameQuery = useGetEnvironmentNameQuery;
exports.useGetEnvironmentNameLazyQuery = useGetEnvironmentNameLazyQuery;
exports.useGetSubjectDraftQuery = useGetSubjectDraftQuery;
exports.useGetSubjectDraftLazyQuery = useGetSubjectDraftLazyQuery;
exports.useGetAvailableDatesForOnboardingCallQuery = useGetAvailableDatesForOnboardingCallQuery;
exports.useGetAvailableDatesForOnboardingCallLazyQuery = useGetAvailableDatesForOnboardingCallLazyQuery;
exports.useGetAvailableTimesForOnboardingCallQuery = useGetAvailableTimesForOnboardingCallQuery;
exports.useGetAvailableTimesForOnboardingCallLazyQuery = useGetAvailableTimesForOnboardingCallLazyQuery;
exports.useGetInfoForAnalyticsQuery = useGetInfoForAnalyticsQuery;
exports.useGetInfoForAnalyticsLazyQuery = useGetInfoForAnalyticsLazyQuery;
exports.useGetScheduledOnboardingCallQuery = useGetScheduledOnboardingCallQuery;
exports.useGetScheduledOnboardingCallLazyQuery = useGetScheduledOnboardingCallLazyQuery;
exports.useGetOnboardingCallInfoQuery = useGetOnboardingCallInfoQuery;
exports.useGetOnboardingCallInfoLazyQuery = useGetOnboardingCallInfoLazyQuery;
exports.useGetCustomFieldsValidationQuery = useGetCustomFieldsValidationQuery;
exports.useGetCustomFieldsValidationLazyQuery = useGetCustomFieldsValidationLazyQuery;
exports.useGetSiteTrialQuery = useGetSiteTrialQuery;
exports.useGetSiteTrialLazyQuery = useGetSiteTrialLazyQuery;
exports.useGetSupportedLanguagesQuery = useGetSupportedLanguagesQuery;
exports.useGetSupportedLanguagesLazyQuery = useGetSupportedLanguagesLazyQuery;
var client_1 = require("@apollo/client");
var Apollo = __importStar(require("@apollo/client"));
var defaultOptions = {};
var AccessType;
(function (AccessType) {
    AccessType["Download"] = "download";
    AccessType["Upload"] = "upload";
})(AccessType || (exports.AccessType = AccessType = {}));
var ActivitiesPaginationMode;
(function (ActivitiesPaginationMode) {
    ActivitiesPaginationMode["Backward"] = "Backward";
    ActivitiesPaginationMode["Bidirectional"] = "Bidirectional";
    ActivitiesPaginationMode["Forward"] = "Forward";
})(ActivitiesPaginationMode || (exports.ActivitiesPaginationMode = ActivitiesPaginationMode = {}));
var ActivityFrequency;
(function (ActivityFrequency) {
    ActivityFrequency["Biweekly"] = "Biweekly";
    ActivityFrequency["Daily"] = "Daily";
    ActivityFrequency["Monthly"] = "Monthly";
    ActivityFrequency["Once"] = "Once";
    ActivityFrequency["Triweekly"] = "Triweekly";
    ActivityFrequency["Weekly"] = "Weekly";
})(ActivityFrequency || (exports.ActivityFrequency = ActivityFrequency = {}));
var ActivityKind;
(function (ActivityKind) {
    ActivityKind["Appointment"] = "Appointment";
    ActivityKind["Goal"] = "Goal";
    ActivityKind["Task"] = "Task";
})(ActivityKind || (exports.ActivityKind = ActivityKind = {}));
var ActivityReminder;
(function (ActivityReminder) {
    ActivityReminder["FifteenMinutes"] = "FifteenMinutes";
    ActivityReminder["Never"] = "Never";
    ActivityReminder["OneDay"] = "OneDay";
    ActivityReminder["TenMinutes"] = "TenMinutes";
    ActivityReminder["ThirtyMinutes"] = "ThirtyMinutes";
})(ActivityReminder || (exports.ActivityReminder = ActivityReminder = {}));
var AddressValidationStatus;
(function (AddressValidationStatus) {
    AddressValidationStatus["Invalid"] = "Invalid";
    AddressValidationStatus["MissingComplement"] = "MissingComplement";
    AddressValidationStatus["Ok"] = "Ok";
})(AddressValidationStatus || (exports.AddressValidationStatus = AddressValidationStatus = {}));
var AppointmentDuration;
(function (AppointmentDuration) {
    AppointmentDuration["FifteenMinutes"] = "FifteenMinutes";
    AppointmentDuration["OneHour"] = "OneHour";
    AppointmentDuration["ThirtyMinutes"] = "ThirtyMinutes";
})(AppointmentDuration || (exports.AppointmentDuration = AppointmentDuration = {}));
var AppointmentLocation;
(function (AppointmentLocation) {
    AppointmentLocation["InPerson"] = "InPerson";
    AppointmentLocation["Virtual"] = "Virtual";
})(AppointmentLocation || (exports.AppointmentLocation = AppointmentLocation = {}));
var AssignType;
(function (AssignType) {
    AssignType["DhAndCaretaker"] = "dh_and_caretaker";
    AssignType["Manual"] = "manual";
})(AssignType || (exports.AssignType = AssignType = {}));
var AssignmentType;
(function (AssignmentType) {
    AssignmentType["Available"] = "available";
    AssignmentType["Required"] = "required";
})(AssignmentType || (exports.AssignmentType = AssignmentType = {}));
var CoachPrefType;
(function (CoachPrefType) {
    CoachPrefType["Female"] = "female";
    CoachPrefType["Male"] = "male";
    CoachPrefType["NoPref"] = "no_pref";
})(CoachPrefType || (exports.CoachPrefType = CoachPrefType = {}));
var ContentExplanationType;
(function (ContentExplanationType) {
    ContentExplanationType["Image"] = "image";
    ContentExplanationType["Text"] = "text";
})(ContentExplanationType || (exports.ContentExplanationType = ContentExplanationType = {}));
var CourseAssignmentTypes;
(function (CourseAssignmentTypes) {
    CourseAssignmentTypes["None"] = "none";
    CourseAssignmentTypes["Site"] = "site";
    CourseAssignmentTypes["Trial"] = "trial";
})(CourseAssignmentTypes || (exports.CourseAssignmentTypes = CourseAssignmentTypes = {}));
var CustomFieldName;
(function (CustomFieldName) {
    CustomFieldName["Sachets"] = "sachets";
    CustomFieldName["TreatmentStartDate"] = "treatmentStartDate";
})(CustomFieldName || (exports.CustomFieldName = CustomFieldName = {}));
var DateQuestionInitialState;
(function (DateQuestionInitialState) {
    DateQuestionInitialState["NoPreloadedDate"] = "noPreloadedDate";
    DateQuestionInitialState["PreloadedWithCurrentDate"] = "preloadedWithCurrentDate";
})(DateQuestionInitialState || (exports.DateQuestionInitialState = DateQuestionInitialState = {}));
var DeliveryKey;
(function (DeliveryKey) {
    DeliveryKey["WeekAFirst"] = "weekAFirst";
    DeliveryKey["WeekASecond"] = "weekASecond";
    DeliveryKey["WeekBFirst"] = "weekBFirst";
    DeliveryKey["WeekBSecond"] = "weekBSecond";
    DeliveryKey["WeekCFirst"] = "weekCFirst";
    DeliveryKey["WeekCSecond"] = "weekCSecond";
})(DeliveryKey || (exports.DeliveryKey = DeliveryKey = {}));
var DeliverySettings;
(function (DeliverySettings) {
    DeliverySettings["Fewest"] = "fewest";
    DeliverySettings["Freshest"] = "freshest";
})(DeliverySettings || (exports.DeliverySettings = DeliverySettings = {}));
var DeliveryStatus;
(function (DeliveryStatus) {
    DeliveryStatus["Closed"] = "closed";
    DeliveryStatus["Open"] = "open";
})(DeliveryStatus || (exports.DeliveryStatus = DeliveryStatus = {}));
var DeviceNotificationChannel;
(function (DeviceNotificationChannel) {
    DeviceNotificationChannel["Apns"] = "APNS";
    DeviceNotificationChannel["Gcm"] = "GCM";
})(DeviceNotificationChannel || (exports.DeviceNotificationChannel = DeviceNotificationChannel = {}));
var DishType;
(function (DishType) {
    DishType["Meal"] = "meal";
    DishType["Snack"] = "snack";
})(DishType || (exports.DishType = DishType = {}));
var DocumentOverwriteOption;
(function (DocumentOverwriteOption) {
    DocumentOverwriteOption["Overwrite"] = "overwrite";
    DocumentOverwriteOption["Preserve"] = "preserve";
})(DocumentOverwriteOption || (exports.DocumentOverwriteOption = DocumentOverwriteOption = {}));
var DocumentOwnerFilter;
(function (DocumentOwnerFilter) {
    DocumentOwnerFilter["Both"] = "both";
    DocumentOwnerFilter["Coach"] = "coach";
    DocumentOwnerFilter["Patient"] = "patient";
})(DocumentOwnerFilter || (exports.DocumentOwnerFilter = DocumentOwnerFilter = {}));
var DraftFillingStatus;
(function (DraftFillingStatus) {
    DraftFillingStatus["Complete"] = "complete";
    DraftFillingStatus["Incomplete"] = "incomplete";
})(DraftFillingStatus || (exports.DraftFillingStatus = DraftFillingStatus = {}));
var FeatureFlagType;
(function (FeatureFlagType) {
    FeatureFlagType["Mobile"] = "Mobile";
    FeatureFlagType["Web"] = "Web";
})(FeatureFlagType || (exports.FeatureFlagType = FeatureFlagType = {}));
var FilterOp;
(function (FilterOp) {
    FilterOp["Equal"] = "equal";
    FilterOp["Includes"] = "includes";
})(FilterOp || (exports.FilterOp = FilterOp = {}));
var FoodProgramStatus;
(function (FoodProgramStatus) {
    FoodProgramStatus["Active"] = "active";
    FoodProgramStatus["Archived"] = "archived";
})(FoodProgramStatus || (exports.FoodProgramStatus = FoodProgramStatus = {}));
var FormBuilderQuestionType;
(function (FormBuilderQuestionType) {
    FormBuilderQuestionType["Binary"] = "Binary";
    FormBuilderQuestionType["Date"] = "Date";
    FormBuilderQuestionType["MultipleChoice"] = "MultipleChoice";
    FormBuilderQuestionType["Number"] = "Number";
    FormBuilderQuestionType["OpenText"] = "OpenText";
    FormBuilderQuestionType["Scale"] = "Scale";
    FormBuilderQuestionType["SingleChoice"] = "SingleChoice";
})(FormBuilderQuestionType || (exports.FormBuilderQuestionType = FormBuilderQuestionType = {}));
var LogicAction;
(function (LogicAction) {
    LogicAction["Hide"] = "Hide";
    LogicAction["Show"] = "Show";
})(LogicAction || (exports.LogicAction = LogicAction = {}));
var LogicCondition;
(function (LogicCondition) {
    LogicCondition["Contains"] = "Contains";
    LogicCondition["EqualTo"] = "EqualTo";
    LogicCondition["NotContains"] = "NotContains";
    LogicCondition["NotEqualTo"] = "NotEqualTo";
})(LogicCondition || (exports.LogicCondition = LogicCondition = {}));
var MainGoalType;
(function (MainGoalType) {
    MainGoalType["Connect"] = "connect";
    MainGoalType["Learn"] = "learn";
    MainGoalType["Support"] = "support";
    MainGoalType["Track"] = "track";
})(MainGoalType || (exports.MainGoalType = MainGoalType = {}));
var MealType;
(function (MealType) {
    MealType["Breakfast"] = "breakfast";
    MealType["Dinner"] = "dinner";
    MealType["Lunch"] = "lunch";
    MealType["Sides"] = "sides";
    MealType["Snacks"] = "snacks";
})(MealType || (exports.MealType = MealType = {}));
var MenuType;
(function (MenuType) {
    MenuType["QuickShip"] = "quick_ship";
    MenuType["Regular"] = "regular";
})(MenuType || (exports.MenuType = MenuType = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["None"] = "none";
    NotificationType["Push"] = "push";
    NotificationType["Sms"] = "sms";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
var NotificationTypeV2;
(function (NotificationTypeV2) {
    NotificationTypeV2["Push"] = "push";
    NotificationTypeV2["Sms"] = "sms";
})(NotificationTypeV2 || (exports.NotificationTypeV2 = NotificationTypeV2 = {}));
var PhysicalActivityLevel;
(function (PhysicalActivityLevel) {
    PhysicalActivityLevel["Moderately"] = "moderately";
    PhysicalActivityLevel["Sedentary"] = "sedentary";
    PhysicalActivityLevel["SlightlyActive"] = "slightly_active";
    PhysicalActivityLevel["VeryActive"] = "very_active";
})(PhysicalActivityLevel || (exports.PhysicalActivityLevel = PhysicalActivityLevel = {}));
var PlatformType;
(function (PlatformType) {
    PlatformType["Android"] = "Android";
    PlatformType["Ios"] = "Ios";
    PlatformType["Web"] = "Web";
})(PlatformType || (exports.PlatformType = PlatformType = {}));
var RecipeType;
(function (RecipeType) {
    RecipeType["Breakfast"] = "Breakfast";
    RecipeType["Dinner"] = "Dinner";
    RecipeType["Lunch"] = "Lunch";
    RecipeType["Snack"] = "Snack";
})(RecipeType || (exports.RecipeType = RecipeType = {}));
var RegistrationStatus;
(function (RegistrationStatus) {
    RegistrationStatus["Confirmed"] = "CONFIRMED";
    RegistrationStatus["ForceChangePassword"] = "FORCE_CHANGE_PASSWORD";
})(RegistrationStatus || (exports.RegistrationStatus = RegistrationStatus = {}));
var ScheduledItemType;
(function (ScheduledItemType) {
    ScheduledItemType["Appointment"] = "appointment";
    ScheduledItemType["Article"] = "article";
    ScheduledItemType["Bhb"] = "bhb";
    ScheduledItemType["Goal"] = "goal";
    ScheduledItemType["Learning"] = "learning";
    ScheduledItemType["Medication"] = "medication";
    ScheduledItemType["Survey"] = "survey";
    ScheduledItemType["Task"] = "task";
})(ScheduledItemType || (exports.ScheduledItemType = ScheduledItemType = {}));
var ShipmentLabelStatus;
(function (ShipmentLabelStatus) {
    ShipmentLabelStatus["Error"] = "ERROR";
    ShipmentLabelStatus["Queued"] = "QUEUED";
    ShipmentLabelStatus["Refunded"] = "REFUNDED";
    ShipmentLabelStatus["Refundpending"] = "REFUNDPENDING";
    ShipmentLabelStatus["Refundrejected"] = "REFUNDREJECTED";
    ShipmentLabelStatus["Success"] = "SUCCESS";
    ShipmentLabelStatus["Waiting"] = "WAITING";
})(ShipmentLabelStatus || (exports.ShipmentLabelStatus = ShipmentLabelStatus = {}));
var SiteStaffRole;
(function (SiteStaffRole) {
    SiteStaffRole["Crc"] = "crc";
    SiteStaffRole["Nurse"] = "nurse";
    SiteStaffRole["Pi"] = "pi";
    SiteStaffRole["SubI"] = "sub_i";
})(SiteStaffRole || (exports.SiteStaffRole = SiteStaffRole = {}));
var SiteStatus;
(function (SiteStatus) {
    SiteStatus["Active"] = "active";
    SiteStatus["Inactive"] = "inactive";
})(SiteStatus || (exports.SiteStatus = SiteStatus = {}));
var SortByOrder;
(function (SortByOrder) {
    SortByOrder["Asc"] = "asc";
    SortByOrder["Desc"] = "desc";
})(SortByOrder || (exports.SortByOrder = SortByOrder = {}));
var StudyDietOptions;
(function (StudyDietOptions) {
    StudyDietOptions["All"] = "All";
    StudyDietOptions["Most"] = "Most";
    StudyDietOptions["None"] = "None";
    StudyDietOptions["Some"] = "Some";
})(StudyDietOptions || (exports.StudyDietOptions = StudyDietOptions = {}));
var SubjectStatus;
(function (SubjectStatus) {
    SubjectStatus["CoachApproved"] = "coach_approved";
    SubjectStatus["Discontinued"] = "discontinued";
    SubjectStatus["Draft"] = "draft";
    SubjectStatus["Ongoing"] = "ongoing";
    SubjectStatus["ScreenFailed"] = "screen_failed";
    SubjectStatus["Screening"] = "screening";
    SubjectStatus["StudyCompleted"] = "study_completed";
})(SubjectStatus || (exports.SubjectStatus = SubjectStatus = {}));
var SurveyFrequency;
(function (SurveyFrequency) {
    SurveyFrequency["Biweekly"] = "Biweekly";
    SurveyFrequency["Daily"] = "Daily";
    SurveyFrequency["DaysOnDiet"] = "DaysOnDiet";
    SurveyFrequency["Monthly"] = "Monthly";
    SurveyFrequency["Once"] = "Once";
    SurveyFrequency["Triweekly"] = "Triweekly";
    SurveyFrequency["Weekly"] = "Weekly";
})(SurveyFrequency || (exports.SurveyFrequency = SurveyFrequency = {}));
var SurveyQuestionType;
(function (SurveyQuestionType) {
    SurveyQuestionType["Binary"] = "Binary";
    SurveyQuestionType["Decimal"] = "Decimal";
    SurveyQuestionType["Explanation"] = "Explanation";
    SurveyQuestionType["Meal"] = "Meal";
    SurveyQuestionType["MultipleChoice"] = "MultipleChoice";
    SurveyQuestionType["Number"] = "Number";
    SurveyQuestionType["OpenText"] = "OpenText";
    SurveyQuestionType["Scale"] = "Scale";
    SurveyQuestionType["SingleChoice"] = "SingleChoice";
    SurveyQuestionType["Snack"] = "Snack";
    SurveyQuestionType["Time"] = "Time";
})(SurveyQuestionType || (exports.SurveyQuestionType = SurveyQuestionType = {}));
var SurveyStatus;
(function (SurveyStatus) {
    SurveyStatus["Done"] = "Done";
    SurveyStatus["Late"] = "Late";
    SurveyStatus["Missing"] = "Missing";
    SurveyStatus["Open"] = "Open";
})(SurveyStatus || (exports.SurveyStatus = SurveyStatus = {}));
var TrackingStatus;
(function (TrackingStatus) {
    TrackingStatus["BeingPrepared"] = "BEING_PREPARED";
    TrackingStatus["Delivered"] = "DELIVERED";
    TrackingStatus["Failure"] = "FAILURE";
    TrackingStatus["Returned"] = "RETURNED";
    TrackingStatus["Transit"] = "TRANSIT";
    TrackingStatus["Unknown"] = "UNKNOWN";
})(TrackingStatus || (exports.TrackingStatus = TrackingStatus = {}));
var TrialStatus;
(function (TrialStatus) {
    TrialStatus["ActiveNotRecruiting"] = "active_not_recruiting";
    TrialStatus["Completed"] = "completed";
    TrialStatus["NotYetRecruiting"] = "not_yet_recruiting";
    TrialStatus["Recruiting"] = "recruiting";
    TrialStatus["Terminated"] = "terminated";
})(TrialStatus || (exports.TrialStatus = TrialStatus = {}));
var UserGender;
(function (UserGender) {
    UserGender["Female"] = "female";
    UserGender["Male"] = "male";
})(UserGender || (exports.UserGender = UserGender = {}));
var UserType;
(function (UserType) {
    UserType["Caretaker"] = "caretaker";
    UserType["ClinicalTrialPatient"] = "clinical_trial_patient";
    UserType["Coach"] = "coach";
    UserType["CustomerService"] = "customer_service";
    UserType["Foodops"] = "foodops";
    UserType["Patient"] = "patient";
    UserType["SiteStaff"] = "site_staff";
})(UserType || (exports.UserType = UserType = {}));
var ValidationType;
(function (ValidationType) {
    ValidationType["Date"] = "date";
    ValidationType["NotAllowed"] = "notAllowed";
})(ValidationType || (exports.ValidationType = ValidationType = {}));
var WeekDay;
(function (WeekDay) {
    WeekDay["Fri"] = "Fri";
    WeekDay["Mon"] = "Mon";
    WeekDay["Sat"] = "Sat";
    WeekDay["Sun"] = "Sun";
    WeekDay["Thu"] = "Thu";
    WeekDay["Tue"] = "Tue";
    WeekDay["Wed"] = "Wed";
})(WeekDay || (exports.WeekDay = WeekDay = {}));
exports.AddSubjectDocument = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation addSubject($input: AddSubjectInput!) {\n  addSubject(input: $input) {\n    id\n    address {\n      complement\n      formatted\n      locality\n      region\n      postal_code\n      street_address\n    }\n    birthdate\n    caregiver {\n      name\n      primary_caregiver_email\n    }\n    email\n    gender\n    height\n    name\n    phone_number\n    physical_activity_level\n    site_staff_questions\n    subject_id\n    weight\n    onboardingCallScheduled\n    language\n    onboardingCallNeeded\n  }\n}\n    "], ["\n    mutation addSubject($input: AddSubjectInput!) {\n  addSubject(input: $input) {\n    id\n    address {\n      complement\n      formatted\n      locality\n      region\n      postal_code\n      street_address\n    }\n    birthdate\n    caregiver {\n      name\n      primary_caregiver_email\n    }\n    email\n    gender\n    height\n    name\n    phone_number\n    physical_activity_level\n    site_staff_questions\n    subject_id\n    weight\n    onboardingCallScheduled\n    language\n    onboardingCallNeeded\n  }\n}\n    "])));
/**
 * __useAddSubjectMutation__
 *
 * To run a mutation, you first call `useAddSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubjectMutation, { data, loading, error }] = useAddSubjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useAddSubjectMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.AddSubjectDocument, options);
}
exports.UpdateSubjectDocument = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation updateSubject($patientId: ID!, $input: UpdateSubjectInput!) {\n  updateSubject(patientId: $patientId, input: $input) {\n    id\n    address {\n      complement\n      formatted\n      locality\n      region\n      postal_code\n      street_address\n    }\n    birthdate\n    caregiver {\n      name\n      primary_caregiver_email\n    }\n    email\n    gender\n    height\n    name\n    phone_number\n    physical_activity_level\n    site_staff_questions\n    subject_id\n    weight\n    onboardingCallScheduled\n    language\n  }\n}\n    "], ["\n    mutation updateSubject($patientId: ID!, $input: UpdateSubjectInput!) {\n  updateSubject(patientId: $patientId, input: $input) {\n    id\n    address {\n      complement\n      formatted\n      locality\n      region\n      postal_code\n      street_address\n    }\n    birthdate\n    caregiver {\n      name\n      primary_caregiver_email\n    }\n    email\n    gender\n    height\n    name\n    phone_number\n    physical_activity_level\n    site_staff_questions\n    subject_id\n    weight\n    onboardingCallScheduled\n    language\n  }\n}\n    "])));
/**
 * __useUpdateSubjectMutation__
 *
 * To run a mutation, you first call `useUpdateSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubjectMutation, { data, loading, error }] = useUpdateSubjectMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateSubjectMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.UpdateSubjectDocument, options);
}
exports.SaveSubjectDraftDocument = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation saveSubjectDraft($input: SaveSubjectDraftInput!) {\n  saveSubjectDraft(input: $input) {\n    id\n    siteId\n    firstName\n    lastName\n    subjectId\n    gender\n    birthdate\n    weight\n    height\n    physicalActivityLevel\n    fillingStatus\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n      addressValidationStatus\n    }\n    phoneNumber\n    email\n    emailConfirmation\n    caregiver {\n      hasCaregiver\n      primary_caregiver_email\n      name\n    }\n    questions\n    treatmentStartDate\n    trial {\n      id\n      protocol_number\n      protocol_abbreviation\n      status\n    }\n    language\n  }\n}\n    "], ["\n    mutation saveSubjectDraft($input: SaveSubjectDraftInput!) {\n  saveSubjectDraft(input: $input) {\n    id\n    siteId\n    firstName\n    lastName\n    subjectId\n    gender\n    birthdate\n    weight\n    height\n    physicalActivityLevel\n    fillingStatus\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n      addressValidationStatus\n    }\n    phoneNumber\n    email\n    emailConfirmation\n    caregiver {\n      hasCaregiver\n      primary_caregiver_email\n      name\n    }\n    questions\n    treatmentStartDate\n    trial {\n      id\n      protocol_number\n      protocol_abbreviation\n      status\n    }\n    language\n  }\n}\n    "])));
/**
 * __useSaveSubjectDraftMutation__
 *
 * To run a mutation, you first call `useSaveSubjectDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSubjectDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSubjectDraftMutation, { data, loading, error }] = useSaveSubjectDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useSaveSubjectDraftMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.SaveSubjectDraftDocument, options);
}
exports.DeleteSubjectDraftDocument = (0, client_1.gql)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation deleteSubjectDraft($draftId: ID!) {\n  deleteSubjectDraft(draftId: $draftId)\n}\n    "], ["\n    mutation deleteSubjectDraft($draftId: ID!) {\n  deleteSubjectDraft(draftId: $draftId)\n}\n    "])));
/**
 * __useDeleteSubjectDraftMutation__
 *
 * To run a mutation, you first call `useDeleteSubjectDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubjectDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubjectDraftMutation, { data, loading, error }] = useDeleteSubjectDraftMutation({
 *   variables: {
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
function useDeleteSubjectDraftMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.DeleteSubjectDraftDocument, options);
}
exports.EditSubjectDraftDocument = (0, client_1.gql)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    mutation editSubjectDraft($draftId: ID!, $input: EditSubjectDraftInput!) {\n  editSubjectDraft(draftId: $draftId, input: $input) {\n    id\n    siteId\n    firstName\n    lastName\n    subjectId\n    gender\n    birthdate\n    weight\n    height\n    physicalActivityLevel\n    fillingStatus\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n      addressValidationStatus\n    }\n    phoneNumber\n    email\n    emailConfirmation\n    caregiver {\n      hasCaregiver\n      primary_caregiver_email\n      name\n    }\n    questions\n    treatmentStartDate\n    trial {\n      id\n      protocol_number\n      protocol_abbreviation\n      status\n    }\n    language\n  }\n}\n    "], ["\n    mutation editSubjectDraft($draftId: ID!, $input: EditSubjectDraftInput!) {\n  editSubjectDraft(draftId: $draftId, input: $input) {\n    id\n    siteId\n    firstName\n    lastName\n    subjectId\n    gender\n    birthdate\n    weight\n    height\n    physicalActivityLevel\n    fillingStatus\n    address {\n      formatted\n      street_address\n      locality\n      region\n      postal_code\n      country\n      complement\n      addressValidationStatus\n    }\n    phoneNumber\n    email\n    emailConfirmation\n    caregiver {\n      hasCaregiver\n      primary_caregiver_email\n      name\n    }\n    questions\n    treatmentStartDate\n    trial {\n      id\n      protocol_number\n      protocol_abbreviation\n      status\n    }\n    language\n  }\n}\n    "])));
/**
 * __useEditSubjectDraftMutation__
 *
 * To run a mutation, you first call `useEditSubjectDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSubjectDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSubjectDraftMutation, { data, loading, error }] = useEditSubjectDraftMutation({
 *   variables: {
 *      draftId: // value for 'draftId'
 *      input: // value for 'input'
 *   },
 * });
 */
function useEditSubjectDraftMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.EditSubjectDraftDocument, options);
}
exports.CancelPatientAcuityAppointmentDocument = (0, client_1.gql)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation cancelPatientAcuityAppointment($patientId: ID!, $appointmentId: Int!) {\n  cancelPatientAcuityAppointment(\n    patientId: $patientId\n    appointmentId: $appointmentId\n  )\n}\n    "], ["\n    mutation cancelPatientAcuityAppointment($patientId: ID!, $appointmentId: Int!) {\n  cancelPatientAcuityAppointment(\n    patientId: $patientId\n    appointmentId: $appointmentId\n  )\n}\n    "])));
/**
 * __useCancelPatientAcuityAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelPatientAcuityAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPatientAcuityAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPatientAcuityAppointmentMutation, { data, loading, error }] = useCancelPatientAcuityAppointmentMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
function useCancelPatientAcuityAppointmentMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.CancelPatientAcuityAppointmentDocument, options);
}
exports.ScheduleOnboardingCallDocument = (0, client_1.gql)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    mutation scheduleOnboardingCall($input: OnboardingCallInput!) {\n  scheduleOnboardingCall(input: $input) {\n    id\n    type\n    datetime\n    duration\n  }\n}\n    "], ["\n    mutation scheduleOnboardingCall($input: OnboardingCallInput!) {\n  scheduleOnboardingCall(input: $input) {\n    id\n    type\n    datetime\n    duration\n  }\n}\n    "])));
/**
 * __useScheduleOnboardingCallMutation__
 *
 * To run a mutation, you first call `useScheduleOnboardingCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleOnboardingCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleOnboardingCallMutation, { data, loading, error }] = useScheduleOnboardingCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useScheduleOnboardingCallMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.ScheduleOnboardingCallDocument, options);
}
exports.ResendOneTimePasswordDocument = (0, client_1.gql)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    mutation resendOneTimePassword($patientId: ID!) {\n  resendOneTimePassword(patientId: $patientId)\n}\n    "], ["\n    mutation resendOneTimePassword($patientId: ID!) {\n  resendOneTimePassword(patientId: $patientId)\n}\n    "])));
/**
 * __useResendOneTimePasswordMutation__
 *
 * To run a mutation, you first call `useResendOneTimePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendOneTimePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendOneTimePasswordMutation, { data, loading, error }] = useResendOneTimePasswordMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
function useResendOneTimePasswordMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.ResendOneTimePasswordDocument, options);
}
exports.UpdateUserSessionInfoDocument = (0, client_1.gql)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    mutation updateUserSessionInfo($props: UserSessionInfoInput!) {\n  updateUserSessionInfo(props: $props)\n}\n    "], ["\n    mutation updateUserSessionInfo($props: UserSessionInfoInput!) {\n  updateUserSessionInfo(props: $props)\n}\n    "])));
/**
 * __useUpdateUserSessionInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserSessionInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSessionInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSessionInfoMutation, { data, loading, error }] = useUpdateUserSessionInfoMutation({
 *   variables: {
 *      props: // value for 'props'
 *   },
 * });
 */
function useUpdateUserSessionInfoMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(exports.UpdateUserSessionInfoDocument, options);
}
exports.GetPatientUserDocument = (0, client_1.gql)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    query getPatientUser($patientId: ID!) {\n  patientUser(patientId: $patientId) {\n    id\n    address {\n      complement\n      formatted\n      locality\n      region\n      postal_code\n      street_address\n      country\n    }\n    birthdate\n    caregiver {\n      name\n      primary_caregiver_email\n    }\n    email\n    gender\n    height\n    name\n    phone_number\n    physical_activity_level\n    site_staff_questions\n    subject_id\n    weight\n    onboardingCallScheduled\n    subjectTrialStatus\n    treatmentStartDate\n    trial {\n      id\n      protocol_abbreviation\n    }\n    registrationStatus\n    language\n    onboardingCallNeeded\n  }\n}\n    "], ["\n    query getPatientUser($patientId: ID!) {\n  patientUser(patientId: $patientId) {\n    id\n    address {\n      complement\n      formatted\n      locality\n      region\n      postal_code\n      street_address\n      country\n    }\n    birthdate\n    caregiver {\n      name\n      primary_caregiver_email\n    }\n    email\n    gender\n    height\n    name\n    phone_number\n    physical_activity_level\n    site_staff_questions\n    subject_id\n    weight\n    onboardingCallScheduled\n    subjectTrialStatus\n    treatmentStartDate\n    trial {\n      id\n      protocol_abbreviation\n    }\n    registrationStatus\n    language\n    onboardingCallNeeded\n  }\n}\n    "])));
/**
 * __useGetPatientUserQuery__
 *
 * To run a query within a React component, call `useGetPatientUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientUserQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
function useGetPatientUserQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetPatientUserDocument, options);
}
function useGetPatientUserLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetPatientUserDocument, options);
}
exports.GetProfileDocument = (0, client_1.gql)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    query getProfile {\n  me {\n    id\n    type\n    email\n    phone_number\n    name\n    birthdate\n    picture\n    is_active\n    groups\n    role\n    site {\n      id\n      name\n      abbreviation\n      siteTrials {\n        site_identification\n        status\n        trial {\n          id\n        }\n        languages\n      }\n    }\n  }\n}\n    "], ["\n    query getProfile {\n  me {\n    id\n    type\n    email\n    phone_number\n    name\n    birthdate\n    picture\n    is_active\n    groups\n    role\n    site {\n      id\n      name\n      abbreviation\n      siteTrials {\n        site_identification\n        status\n        trial {\n          id\n        }\n        languages\n      }\n    }\n  }\n}\n    "])));
/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
function useGetProfileQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetProfileDocument, options);
}
function useGetProfileLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetProfileDocument, options);
}
exports.GetSiteTrialsFromSiteStaffUserDocument = (0, client_1.gql)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    query getSiteTrialsFromSiteStaffUser($hideTerminatedTrials: Boolean = false) {\n  siteTrials(hideTerminatedTrials: $hideTerminatedTrials) {\n    id\n    protocol_abbreviation\n    protocol_number\n    status\n    description\n    number_of_patients\n    missingOnboardingCallCount\n    numberOfDrafts\n  }\n}\n    "], ["\n    query getSiteTrialsFromSiteStaffUser($hideTerminatedTrials: Boolean = false) {\n  siteTrials(hideTerminatedTrials: $hideTerminatedTrials) {\n    id\n    protocol_abbreviation\n    protocol_number\n    status\n    description\n    number_of_patients\n    missingOnboardingCallCount\n    numberOfDrafts\n  }\n}\n    "])));
/**
 * __useGetSiteTrialsFromSiteStaffUserQuery__
 *
 * To run a query within a React component, call `useGetSiteTrialsFromSiteStaffUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteTrialsFromSiteStaffUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteTrialsFromSiteStaffUserQuery({
 *   variables: {
 *      hideTerminatedTrials: // value for 'hideTerminatedTrials'
 *   },
 * });
 */
function useGetSiteTrialsFromSiteStaffUserQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetSiteTrialsFromSiteStaffUserDocument, options);
}
function useGetSiteTrialsFromSiteStaffUserLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetSiteTrialsFromSiteStaffUserDocument, options);
}
exports.GetTrialDocument = (0, client_1.gql)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    query getTrial($id: ID!) {\n  trial(id: $id) {\n    id\n    protocol_abbreviation\n    protocol_number\n    status\n    description\n  }\n}\n    "], ["\n    query getTrial($id: ID!) {\n  trial(id: $id) {\n    id\n    protocol_abbreviation\n    protocol_number\n    status\n    description\n  }\n}\n    "])));
/**
 * __useGetTrialQuery__
 *
 * To run a query within a React component, call `useGetTrialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrialQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useGetTrialQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetTrialDocument, options);
}
function useGetTrialLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetTrialDocument, options);
}
exports.ListTrialSubjectsDocument = (0, client_1.gql)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    query listTrialSubjects($trialId: ID!, $first: Int = 10, $after: String) {\n  trialSubjects(trialId: $trialId, first: $first, after: $after) {\n    edges {\n      cursor\n      node {\n        id\n        name\n        patientId\n        subjectId\n        status\n        subjectTrialId\n        onboardingCallScheduled\n        fillingStatus\n        onboardingCallNeeded\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalNumberFound\n  }\n}\n    "], ["\n    query listTrialSubjects($trialId: ID!, $first: Int = 10, $after: String) {\n  trialSubjects(trialId: $trialId, first: $first, after: $after) {\n    edges {\n      cursor\n      node {\n        id\n        name\n        patientId\n        subjectId\n        status\n        subjectTrialId\n        onboardingCallScheduled\n        fillingStatus\n        onboardingCallNeeded\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalNumberFound\n  }\n}\n    "])));
/**
 * __useListTrialSubjectsQuery__
 *
 * To run a query within a React component, call `useListTrialSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTrialSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTrialSubjectsQuery({
 *   variables: {
 *      trialId: // value for 'trialId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
function useListTrialSubjectsQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.ListTrialSubjectsDocument, options);
}
function useListTrialSubjectsLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.ListTrialSubjectsDocument, options);
}
exports.GetEnvironmentNameDocument = (0, client_1.gql)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    query getEnvironmentName {\n  environmentName\n}\n    "], ["\n    query getEnvironmentName {\n  environmentName\n}\n    "])));
/**
 * __useGetEnvironmentNameQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentNameQuery({
 *   variables: {
 *   },
 * });
 */
function useGetEnvironmentNameQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetEnvironmentNameDocument, options);
}
function useGetEnvironmentNameLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetEnvironmentNameDocument, options);
}
exports.GetSubjectDraftDocument = (0, client_1.gql)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    query getSubjectDraft($id: ID!) {\n  subjectDraft(id: $id) {\n    id\n    siteId\n    firstName\n    lastName\n    subjectId\n    gender\n    birthdate\n    weight\n    height\n    physicalActivityLevel\n    fillingStatus\n    address {\n      complement\n      country\n      formatted\n      locality\n      region\n      postal_code\n      street_address\n      addressValidationStatus\n    }\n    phoneNumber\n    email\n    emailConfirmation\n    caregiver {\n      hasCaregiver\n      name\n      primary_caregiver_email\n    }\n    questions\n    startDate\n    creatorId\n    subjectTrialStatus\n    treatmentStartDate\n    trial {\n      id\n      protocol_number\n      protocol_abbreviation\n      status\n    }\n    language\n  }\n}\n    "], ["\n    query getSubjectDraft($id: ID!) {\n  subjectDraft(id: $id) {\n    id\n    siteId\n    firstName\n    lastName\n    subjectId\n    gender\n    birthdate\n    weight\n    height\n    physicalActivityLevel\n    fillingStatus\n    address {\n      complement\n      country\n      formatted\n      locality\n      region\n      postal_code\n      street_address\n      addressValidationStatus\n    }\n    phoneNumber\n    email\n    emailConfirmation\n    caregiver {\n      hasCaregiver\n      name\n      primary_caregiver_email\n    }\n    questions\n    startDate\n    creatorId\n    subjectTrialStatus\n    treatmentStartDate\n    trial {\n      id\n      protocol_number\n      protocol_abbreviation\n      status\n    }\n    language\n  }\n}\n    "])));
/**
 * __useGetSubjectDraftQuery__
 *
 * To run a query within a React component, call `useGetSubjectDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectDraftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useGetSubjectDraftQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetSubjectDraftDocument, options);
}
function useGetSubjectDraftLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetSubjectDraftDocument, options);
}
exports.GetAvailableDatesForOnboardingCallDocument = (0, client_1.gql)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    query getAvailableDatesForOnboardingCall($patientId: ID!, $timezone: String!) {\n  availableDatesForOnboardingCall(patientId: $patientId, timezone: $timezone)\n}\n    "], ["\n    query getAvailableDatesForOnboardingCall($patientId: ID!, $timezone: String!) {\n  availableDatesForOnboardingCall(patientId: $patientId, timezone: $timezone)\n}\n    "])));
/**
 * __useGetAvailableDatesForOnboardingCallQuery__
 *
 * To run a query within a React component, call `useGetAvailableDatesForOnboardingCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableDatesForOnboardingCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableDatesForOnboardingCallQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
function useGetAvailableDatesForOnboardingCallQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetAvailableDatesForOnboardingCallDocument, options);
}
function useGetAvailableDatesForOnboardingCallLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetAvailableDatesForOnboardingCallDocument, options);
}
exports.GetAvailableTimesForOnboardingCallDocument = (0, client_1.gql)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    query getAvailableTimesForOnboardingCall($patientId: ID!, $date: String!, $timezone: String!) {\n  availableTimesForOnboardingCall(\n    patientId: $patientId\n    date: $date\n    timezone: $timezone\n  )\n}\n    "], ["\n    query getAvailableTimesForOnboardingCall($patientId: ID!, $date: String!, $timezone: String!) {\n  availableTimesForOnboardingCall(\n    patientId: $patientId\n    date: $date\n    timezone: $timezone\n  )\n}\n    "])));
/**
 * __useGetAvailableTimesForOnboardingCallQuery__
 *
 * To run a query within a React component, call `useGetAvailableTimesForOnboardingCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableTimesForOnboardingCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableTimesForOnboardingCallQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
function useGetAvailableTimesForOnboardingCallQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetAvailableTimesForOnboardingCallDocument, options);
}
function useGetAvailableTimesForOnboardingCallLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetAvailableTimesForOnboardingCallDocument, options);
}
exports.GetInfoForAnalyticsDocument = (0, client_1.gql)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    query getInfoForAnalytics {\n  user: me {\n    userType: type\n    userId: id\n  }\n  keys: apiKeys {\n    analyticsKey: segmentTrackerWeb\n  }\n  environment: environmentName\n}\n    "], ["\n    query getInfoForAnalytics {\n  user: me {\n    userType: type\n    userId: id\n  }\n  keys: apiKeys {\n    analyticsKey: segmentTrackerWeb\n  }\n  environment: environmentName\n}\n    "])));
/**
 * __useGetInfoForAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetInfoForAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoForAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoForAnalyticsQuery({
 *   variables: {
 *   },
 * });
 */
function useGetInfoForAnalyticsQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetInfoForAnalyticsDocument, options);
}
function useGetInfoForAnalyticsLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetInfoForAnalyticsDocument, options);
}
exports.GetScheduledOnboardingCallDocument = (0, client_1.gql)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n    query getScheduledOnboardingCall($patientId: ID!) {\n  scheduledOnboardingCall(patientId: $patientId) {\n    id\n    type\n    datetime\n    duration\n    email\n  }\n}\n    "], ["\n    query getScheduledOnboardingCall($patientId: ID!) {\n  scheduledOnboardingCall(patientId: $patientId) {\n    id\n    type\n    datetime\n    duration\n    email\n  }\n}\n    "])));
/**
 * __useGetScheduledOnboardingCallQuery__
 *
 * To run a query within a React component, call `useGetScheduledOnboardingCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledOnboardingCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledOnboardingCallQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
function useGetScheduledOnboardingCallQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetScheduledOnboardingCallDocument, options);
}
function useGetScheduledOnboardingCallLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetScheduledOnboardingCallDocument, options);
}
exports.GetOnboardingCallInfoDocument = (0, client_1.gql)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n    query getOnboardingCallInfo($patientId: ID!) {\n  onboardingCallInfo(patientId: $patientId) {\n    id\n    active\n    name\n    description\n    duration\n  }\n}\n    "], ["\n    query getOnboardingCallInfo($patientId: ID!) {\n  onboardingCallInfo(patientId: $patientId) {\n    id\n    active\n    name\n    description\n    duration\n  }\n}\n    "])));
/**
 * __useGetOnboardingCallInfoQuery__
 *
 * To run a query within a React component, call `useGetOnboardingCallInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingCallInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingCallInfoQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
function useGetOnboardingCallInfoQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetOnboardingCallInfoDocument, options);
}
function useGetOnboardingCallInfoLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetOnboardingCallInfoDocument, options);
}
exports.GetCustomFieldsValidationDocument = (0, client_1.gql)(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n    query getCustomFieldsValidation($trialId: ID!) {\n  customFieldsValidation(trialId: $trialId) {\n    name\n    required\n    draftValidation {\n      ... on DateValidation {\n        type\n        required\n        min\n        max\n        errorMessage\n      }\n      ... on NotAllowedValidation {\n        type\n      }\n    }\n    submissionValidation {\n      ... on DateValidation {\n        type\n        required\n        min\n        max\n        errorMessage\n      }\n      ... on NotAllowedValidation {\n        type\n      }\n    }\n  }\n}\n    "], ["\n    query getCustomFieldsValidation($trialId: ID!) {\n  customFieldsValidation(trialId: $trialId) {\n    name\n    required\n    draftValidation {\n      ... on DateValidation {\n        type\n        required\n        min\n        max\n        errorMessage\n      }\n      ... on NotAllowedValidation {\n        type\n      }\n    }\n    submissionValidation {\n      ... on DateValidation {\n        type\n        required\n        min\n        max\n        errorMessage\n      }\n      ... on NotAllowedValidation {\n        type\n      }\n    }\n  }\n}\n    "])));
/**
 * __useGetCustomFieldsValidationQuery__
 *
 * To run a query within a React component, call `useGetCustomFieldsValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFieldsValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFieldsValidationQuery({
 *   variables: {
 *      trialId: // value for 'trialId'
 *   },
 * });
 */
function useGetCustomFieldsValidationQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetCustomFieldsValidationDocument, options);
}
function useGetCustomFieldsValidationLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetCustomFieldsValidationDocument, options);
}
exports.GetSiteTrialDocument = (0, client_1.gql)(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n    query getSiteTrial($trialId: ID!) {\n  siteTrial(trialId: $trialId) {\n    onboardingCallNeeded\n    trial {\n      id\n      protocol_abbreviation\n      subjectNumberDigitLength\n      subjectIdTemplatePattern\n    }\n  }\n}\n    "], ["\n    query getSiteTrial($trialId: ID!) {\n  siteTrial(trialId: $trialId) {\n    onboardingCallNeeded\n    trial {\n      id\n      protocol_abbreviation\n      subjectNumberDigitLength\n      subjectIdTemplatePattern\n    }\n  }\n}\n    "])));
/**
 * __useGetSiteTrialQuery__
 *
 * To run a query within a React component, call `useGetSiteTrialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteTrialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteTrialQuery({
 *   variables: {
 *      trialId: // value for 'trialId'
 *   },
 * });
 */
function useGetSiteTrialQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetSiteTrialDocument, options);
}
function useGetSiteTrialLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetSiteTrialDocument, options);
}
exports.GetSupportedLanguagesDocument = (0, client_1.gql)(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n    query getSupportedLanguages {\n  supportedLanguages {\n    code\n    nameEnglish\n    nameNative\n  }\n}\n    "], ["\n    query getSupportedLanguages {\n  supportedLanguages {\n    code\n    nameEnglish\n    nameNative\n  }\n}\n    "])));
/**
 * __useGetSupportedLanguagesQuery__
 *
 * To run a query within a React component, call `useGetSupportedLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportedLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportedLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
function useGetSupportedLanguagesQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.GetSupportedLanguagesDocument, options);
}
function useGetSupportedLanguagesLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.GetSupportedLanguagesDocument, options);
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24;
