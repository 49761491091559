import React, { useMemo } from 'react';
import {
  CoachSiteTrial,
  AssignmentAvailability,
  useAddCoachSiteTrialMutation,
  useGetSiteQuery,
  useListSitesQuery,
  useUpdateCoachSiteTrialMutation,
} from '@fdha/graphql-api-admin';
import {
  Autocomplete,
  Option,
  RadioGroup,
  RadioOption,
  requiredMessage,
  useSnackbar,
  Loader,
  Typography,
} from '@fdha/web-ui-library';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Grid, Paper, Stack } from '@mui/material';
import { getFormikError, parseBackendError } from '@fdha/common-utils';

import {
  assignmentAvailabilityLabels,
  getCalendarConfigPayload,
} from '../../../utils';
import { CalendarConfigInputs } from '../../../components/';

interface AddOrEditCoachSiteTrialProps {
  data?: CoachSiteTrial;
  onSuccess?: (coachId: string) => void;
  onCancel?: () => void;
  loadingCoachSiteTrial?: boolean;
  coachId: string;
}

export interface CalendarConfigSchema {
  calendarId?: string | null;
  appointmentTypeId?: string | null;
}

export interface CoachSiteTrialSchema {
  coachId: string;
  siteTrialId: string;
  site: Option | null;
  trial: Option | null;
  assignmentAvailability: AssignmentAvailability;
  calendarConfiguration: CalendarConfigSchema;
}

const validationSchema = Yup.object().shape({
  site: Yup.object().nullable().required(requiredMessage),
  trial: Yup.object().nullable().required(requiredMessage),
});

const assignmentAvailabilitys: RadioOption[] = [
  {
    label: assignmentAvailabilityLabels[AssignmentAvailability.Available],
    value: AssignmentAvailability.Available,
  },
  {
    label: assignmentAvailabilityLabels[AssignmentAvailability.Preferred],
    value: AssignmentAvailability.Preferred,
  },
  {
    label: assignmentAvailabilityLabels[AssignmentAvailability.NotAvailable],
    value: AssignmentAvailability.NotAvailable,
  },
];

const NUMBER_OF_RESULTS = 10000;

export const AddOrEditCoachSiteTrial: React.FC<
  AddOrEditCoachSiteTrialProps
> = ({ data, onSuccess, onCancel, loadingCoachSiteTrial, coachId }) => {
  const { showSnackbar } = useSnackbar();

  const [addCoachSiteTrial] = useAddCoachSiteTrialMutation();
  const [updateCoachSiteTrial] = useUpdateCoachSiteTrialMutation();

  const isEditing = !!data;

  const initialValues: CoachSiteTrialSchema = {
    coachId: data?.coachId || coachId,
    siteTrialId: data?.siteTrialId || '',
    site: data?.site
      ? {
          id: data.site.id,
          label: data.site.name,
        }
      : null,
    trial: data?.trial
      ? {
          id: data.trial.id,
          label: data.trial.protocol_number || '',
        }
      : null,
    assignmentAvailability:
      data?.assignmentAvailability || AssignmentAvailability.Available,
    calendarConfiguration: {
      calendarId: data?.calendarConfiguration?.calendarId,
      appointmentTypeId: data?.calendarConfiguration?.appointmentTypeId,
    },
  };

  const { data: sitesData, loading: loadingSites } = useListSitesQuery({
    variables: { first: NUMBER_OF_RESULTS },
    fetchPolicy: 'cache-and-network',
  });

  const sitesOptions: Option[] = useMemo(() => {
    return (
      sitesData?.sites.edges.map(({ node }) => ({
        label: node.name,
        id: node.id,
      })) || []
    );
  }, [sitesData?.sites.edges]);

  const getPayload = (values: CoachSiteTrialSchema) => {
    return {
      coachId,
      siteTrialId: values.siteTrialId,
      assignmentAvailability: values.assignmentAvailability,
      calendarConfiguration: getCalendarConfigPayload(
        values.calendarConfiguration
      ),
    };
  };

  const onSubmit = async (values: CoachSiteTrialSchema) => {
    try {
      if (isEditing) {
        await updateCoachSiteTrial({
          variables: {
            id: data.id,
            input: getPayload(values),
          },
        });
      } else {
        await addCoachSiteTrial({
          variables: {
            input: getPayload(values),
          },
        });
      }

      onSuccess?.(coachId);
      showSnackbar({
        message: `Coach Site/Trial ${
          isEditing ? 'updated' : 'added'
        } with success`,
        severity: 'success',
      });
    } catch (error: any) {
      console.error(error);
      const message = parseBackendError(
        error,
        `Error ${isEditing ? 'updating' : 'adding'} Coach Site/Trial Assignment`
      );
      showSnackbar({
        message,
        severity: 'error',
      });
    }
  };

  const {
    handleSubmit,
    errors,
    setFieldValue,
    isSubmitting,
    values,
    handleBlur,
    touched,
  } = useFormik<CoachSiteTrialSchema>({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const { data: dataSite, loading: loadingSite } = useGetSiteQuery({
    variables: { id: values.site?.id || '' },
    skip: !values.site?.id,
  });

  const trialOptions: Option[] =
    dataSite?.site?.siteTrials?.map((siteTrial) => ({
      id: siteTrial.trial?.id || '',
      label: siteTrial.trial?.protocol_number || '',
    })) || [];

  const handleCancel = () => {
    onCancel?.();
    showSnackbar({
      message: 'Changes not saved',
      severity: 'warning',
    });
  };

  const getError = (name: string) => {
    return getFormikError<CoachSiteTrialSchema>(name, errors, touched);
  };

  if (loadingSites || loadingCoachSiteTrial) {
    return <Loader />;
  }
  const handleSiteChange = (value: Option | null) => {
    setFieldValue('site', value);
    setFieldValue('trial', null);
    setFieldValue('siteTrialId', null);
  };

  const handleTrialChange = (value: Option | null) => {
    const siteTrialId = dataSite?.site?.siteTrials?.find(
      (siteTrial) => siteTrial?.trial?.id === value?.id
    )?.id;

    setFieldValue('trial', value);
    setFieldValue('siteTrialId', siteTrialId);
  };

  return (
    <>
      <Paper sx={{ p: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                title="Site"
                placeholder="Select a site"
                options={sitesOptions}
                value={values.site}
                onChange={(_event, value) => handleSiteChange(value)}
                onBlur={handleBlur}
                error={!!getError('site')}
                helperText={getError('site')}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                title="Trial"
                placeholder="Select a trial"
                options={trialOptions}
                value={values.trial}
                onChange={(_event, value) => handleTrialChange(value)}
                onBlur={handleBlur}
                error={!!getError('trial')}
                helperText={getError('trial')}
                disabled={!values.site?.id || !trialOptions.length}
                loading={loadingSite}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                row
                type="default"
                name="assignmentAvailability"
                title="Patient Assignment"
                value={values.assignmentAvailability?.toString()}
                options={assignmentAvailabilitys}
                onChange={(value) => {
                  setFieldValue('assignmentAvailability', value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Acuity Configuration</Typography>
              <Typography variant="body1" mt={1}>
                The coach's default Acuity configuration is set on their
                profile. <br />
                Enter Acuity settings here to use a different Calendar and/or
                Appointment Type for this Site/Trial.
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <CalendarConfigInputs
                calendarIdValue={values.calendarConfiguration?.calendarId}
                appointmentTypeIdValue={
                  values.calendarConfiguration?.appointmentTypeId
                }
                calendarIdName="calendarConfiguration.calendarId"
                appointmentTypeIdName="calendarConfiguration.appointmentTypeId"
                onChange={setFieldValue}
                handleBlur={handleBlur}
              />
            </Grid>
          </Grid>
        </Paper>

        <Stack direction="row" justifyContent="flex-end" spacing={1} mt={4}>
          <Button size="large" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disabled={isSubmitting}
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Stack>
      </Paper>
    </>
  );
};
