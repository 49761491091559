import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  LoginPage,
  ResetPasswordPage,
  ForgotPasswordPage,
  ChangePasswordPage,
  SMSChallengeCodePage,
} from '@fdha/web-auth';
import {
  PlatformType,
  useUpdateUserSessionInfoMutation,
} from '@fdha/graphql-api-admin';

const PublicRoutes = () => {
  const [updateUserSessionInfo] = useUpdateUserSessionInfoMutation();

  const logoUrl = '/images/faeth-logo.svg';

  const handleSignIn = async () => {
    await updateUserSessionInfo({
      variables: {
        props: {
          platform: PlatformType.Web,
          action: 'login',
        },
      },
    });
  };

  return (
    <Routes>
      <Route
        path="*"
        element={<LoginPage logoUrl={logoUrl} onSignIn={handleSignIn} />}
      />
      <Route
        path="/change-password"
        element={<ChangePasswordPage logoUrl={logoUrl} />}
      />
      <Route
        path="/forgot-password"
        element={<ForgotPasswordPage logoUrl={logoUrl} />}
      />
      <Route
        path="/reset-password"
        element={<ResetPasswordPage logoUrl={logoUrl} />}
      />
      <Route
        path="/sms-challenge"
        element={<SMSChallengeCodePage logoUrl={logoUrl} />}
      />
    </Routes>
  );
};

export default PublicRoutes;
