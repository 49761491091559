import {
  BhbData,
  SortByOrder,
  useGetPatientBhbAnswersQuery,
} from '@fdha/graphql-api-admin';
import { DetailedBHBCard, Graph, Icon, Typography } from '@fdha/web-ui-library';
import { Paper, Stack, Switch, useTheme } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isSameDay } from 'date-fns';
import { studyDietIconByValue } from '@fdha/common-utils';
import { XAxis } from 'recharts';
import { groupBy } from 'lodash';

import { getGraphData } from './utils';

interface BHBDataProps {
  patientId: string;
}

const ROWS = 1000;

interface Detail {
  enabled: boolean;
  values: BhbData[];
}

const BHBData: FC<BHBDataProps> = ({ patientId }) => {
  const theme = useTheme();

  const [details, setDetails] = useState<Detail>({
    enabled: true,
    values: [],
  });

  const { data, loading } = useGetPatientBhbAnswersQuery({
    variables: {
      patientId: patientId,
      first: ROWS,
      sortBy: {
        sortBy: [{ field: 'measured_at', order: SortByOrder.Asc }],
      },
    },
    fetchPolicy: 'no-cache',
  });

  const bhbData = useMemo(
    () =>
      (data?.getPatientBhbAnswers.edges.map((edge) => edge.node) ?? []).filter(
        (d) => d.bhb_value
      ),
    [data]
  );

  const setBHBDetails = useCallback(
    (index: number) => {
      const base = bhbData.at(index);

      const data = bhbData.filter((bhb) => {
        return isSameDay(
          new Date(base?.measured_at),
          new Date(bhb.measured_at)
        );
      });

      setDetails((prevDetails) => ({ ...prevDetails, values: data }));
    },
    [bhbData]
  );

  const graphData = useMemo(
    () =>
      getGraphData({
        nodes: bhbData,
        tooltipColor: theme.palette.error.dark,
        clickedNodes: details.enabled ? details.values : [],
      }),
    [bhbData, theme.palette.error.dark, details]
  );

  const uniqueGraphData = useMemo(() => {
    return Object.values(groupBy(graphData, (d) => d.x)).map((d) => d[0]);
  }, [graphData]);

  useEffect(() => {
    if (bhbData.length) {
      setBHBDetails(bhbData.length - 1);
    }
  }, [bhbData, setBHBDetails]);

  return (
    <Paper sx={{ p: 5 }}>
      <Stack spacing={3}>
        <Stack direction="row" alignItems="center">
          <Switch
            checked={details.enabled}
            onChange={(_, checked) =>
              setDetails({ ...details, enabled: checked })
            }
          />
          <Typography variant="body1">Show Study Diet Compliance </Typography>
        </Stack>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          The goal range for <b>BHB</b> level is between <b>0.6 - 3.0 mmol/L</b>
        </Typography>
        <Stack direction={{ xs: 'column', lg: 'row' }} gap={10}>
          <Stack flex={1} maxWidth={{ xs: '100%', lg: '50%' }} gap={2}>
            <Graph
              data={graphData}
              limits={{ bottom: 0.6, upper: 3 }}
              showSkeleton={loading}
              onClick={details.enabled ? setBHBDetails : undefined}
              yAxisUpperLimit={6.5}
              extraAxis={
                details.enabled ? (
                  <XAxis
                    dataKey="x"
                    xAxisId="icons"
                    interval="preserveStartEnd"
                    orientation="bottom"
                    allowDuplicatedCategory={false}
                    tick={(props) => {
                      const { x, y, index } = props;
                      const studyDiet = uniqueGraphData.at(index)?.studyDiet;
                      const studyDietInfo = studyDiet
                        ? {
                            icon: studyDietIconByValue[studyDiet],
                            fill: theme.palette.info.main,
                            label: studyDiet,
                          }
                        : {
                            icon: 'question-mark-outline',
                            fill: theme.palette.text.hint,
                            label: 'Missing',
                          };

                      return (
                        <g transform={`translate(${x},${y})`}>
                          <foreignObject x={-28} y={0} width={60} height={720}>
                            <Stack alignItems="center">
                              <Icon
                                name={studyDietInfo.icon}
                                size="small"
                                fill={studyDietInfo.fill}
                              />
                              <Typography
                                variant="caption"
                                color={theme.palette.text.secondary}
                              >
                                {studyDietInfo.label}
                              </Typography>
                            </Stack>
                          </foreignObject>
                        </g>
                      );
                    }}
                    height={36}
                    tickSize={0}
                  />
                ) : undefined
              }
            />
            {details.enabled && (
              <Typography
                sx={{ ml: 8, textAnchor: 'start' }}
                variant="caption"
                color={theme.palette.text.secondary}
                showSkeleton={loading}
              >
                Study Diet Compliance answers per day
              </Typography>
            )}
          </Stack>
          <Stack flex={1}>
            {details.enabled && details.values.length > 0 && (
              <DetailedBHBCard
                date={details.values[0].measured_at}
                bhbLevels={details.values.map((val) => {
                  return {
                    bhb: val.bhb_value ?? 0,
                    time: new Date(val.measured_at),
                  };
                })}
                studyDiet={details.values[0].studyDietAnswer ?? undefined}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default BHBData;
