"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildLastLoginLabel = exports.startOfFollowingWeek = exports.getNextDay = exports.convertDateToLocalUtc = exports.WeekDayFlag = exports.WeekDay = void 0;
var date_fns_1 = require("date-fns");
var date_fns_tz_1 = require("date-fns-tz");
var WeekDay;
(function (WeekDay) {
    WeekDay[WeekDay["Sun"] = 0] = "Sun";
    WeekDay[WeekDay["Mon"] = 1] = "Mon";
    WeekDay[WeekDay["Tue"] = 2] = "Tue";
    WeekDay[WeekDay["Wed"] = 3] = "Wed";
    WeekDay[WeekDay["Thu"] = 4] = "Thu";
    WeekDay[WeekDay["Fri"] = 5] = "Fri";
    WeekDay[WeekDay["Sat"] = 6] = "Sat";
})(WeekDay || (exports.WeekDay = WeekDay = {}));
var WeekDayFlag;
(function (WeekDayFlag) {
    WeekDayFlag[WeekDayFlag["Sun"] = 1] = "Sun";
    WeekDayFlag[WeekDayFlag["Mon"] = 2] = "Mon";
    WeekDayFlag[WeekDayFlag["Tue"] = 4] = "Tue";
    WeekDayFlag[WeekDayFlag["Wed"] = 8] = "Wed";
    WeekDayFlag[WeekDayFlag["Thu"] = 16] = "Thu";
    WeekDayFlag[WeekDayFlag["Fri"] = 32] = "Fri";
    WeekDayFlag[WeekDayFlag["Sat"] = 64] = "Sat";
})(WeekDayFlag || (exports.WeekDayFlag = WeekDayFlag = {}));
/**
 * Convert date to local time zone
 * @param date Date to convert
 * @returns Date in local time zone
 */
var convertDateToLocalUtc = function (date) {
    return (0, date_fns_tz_1.utcToZonedTime)(date, 'UTC');
};
exports.convertDateToLocalUtc = convertDateToLocalUtc;
/**
 * Return the next day in ISO format
 * @returns String in ISO format
 */
var getNextDay = function () {
    return (0, date_fns_1.formatISO)((0, date_fns_1.addDays)((0, date_fns_1.startOfToday)(), 1));
};
exports.getNextDay = getNextDay;
/**
 * Calculates the start date of the following week based on the given date.
 *
 * @param date - The date from which to calculate the start of the following
 *               week.
 * @param options - An optional object to specify the start day of the week.
 * @param options.weekStartsOn - The day the week starts on (0 for Sunday,
 *                               1 for Monday etc.). Defaults to 1 (Monday).
 * @returns The start date of the following week.
 */
var startOfFollowingWeek = function (date, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.weekStartsOn, weekStartsOn = _c === void 0 ? 1 : _c;
    var startOfWeekDate = (0, date_fns_1.startOfWeek)(date, { weekStartsOn: weekStartsOn });
    return (0, date_fns_1.addWeeks)(startOfWeekDate, 1);
};
exports.startOfFollowingWeek = startOfFollowingWeek;
/**
 * Builds a label for the last login timestamp, including the platform and formatted date.
 *
 * @param timestamp - The date and time of the last login. Can be a Date object or null.
 * @param platform - The platform from which the login occurred.
 * @param timezone - The timezone to format the date in.
 * @returns A string representing the last login label in the format `[platform] MM/dd/yyyy - hh:mm:ss aa zzz`,
 *          or null if any of the parameters are missing or invalid.
 */
var buildLastLoginLabel = function (timestamp, platform, timezone) {
    if (!timestamp || !platform || !timezone) {
        return null;
    }
    var date = new Date(timestamp);
    var zonedTime = (0, date_fns_tz_1.utcToZonedTime)(date, timezone);
    var formattedDate = (0, date_fns_tz_1.format)(zonedTime, 'MM/dd/yyyy - hh:mm:ss aa zzz', {
        timeZone: timezone,
    });
    return "[".concat(platform, "] ").concat(formattedDate);
};
exports.buildLastLoginLabel = buildLastLoginLabel;
