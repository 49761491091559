import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import React, { useEffect, useState } from 'react';
import {
  useDialog,
  useSnackbar,
  Table,
  RowsPerPageOptions,
  SearchableTableHeader,
  completeWithinLabelByValue,
  SplitMenu,
  formatUTCDate,
} from '@fdha/web-ui-library';
import { NetworkStatus } from '@apollo/client';
import {
  AssignmentType,
  ListAllAssignedSurveysDocument,
  SurveyFrequency,
  useGetPatientUserQuery,
  useListAllAssignedSurveysQuery,
  useUnassignSurveyAnytimeMutation,
  useUnassignSurveyMutation,
  WeekDay,
} from '@fdha/graphql-api-admin';
import { parseBackendError } from '@fdha/common-utils';
import { capitalize } from 'lodash';

import {
  AssignSurvey,
  BasePage,
  Chip,
  UserIdentifier,
} from '../../../../../components';
import {
  useFilterBy,
  useSortBy,
  useDebouncedValue,
  useTable,
  useGetUserType,
} from '../../../../../hooks';
import {
  getFrequency,
  headCellsForAllAssignedSchedules,
  AllAssignedSurveyProps,
  mapAssignedSurveyNode,
} from '../../../../../utils';

const AllAssignedSchedules = () => {
  const theme = useTheme();
  const params = useParams();
  const { openDialog, closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [viewOption, setViewOption] = useState<
    'all' | 'available' | 'required'
  >('all');

  const [filterBy, setFilterBy] = useFilterBy<AllAssignedSurveyProps>(
    'label',
    ''
  );
  const [sortBy, setSortBy] = useSortBy<AllAssignedSurveyProps>('label', 'asc');

  const filterByDebounced = useDebouncedValue(filterBy);

  const patientId = params.patientId || '';

  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'all-assigned-schedules',
  });

  const [unassignSurvey] = useUnassignSurveyMutation();
  const [unassignSurveyAnytime] = useUnassignSurveyAnytimeMutation();

  const { data: patientData } = useGetPatientUserQuery({
    variables: {
      id: patientId,
    },
  });

  const { isCsr } = useGetUserType();

  const patientName = patientData?.patientUser?.name;

  const { data, error, fetchMore, loading, networkStatus, refetch } =
    useListAllAssignedSurveysQuery({
      variables: {
        patientId: patientId,
        first: rowsPerPage,
        filterBy: {
          filterBy: [filterByDebounced],
        },
        sortBy: {
          sortBy: [sortBy],
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  const isLoading =
    loading ||
    networkStatus === NetworkStatus.fetchMore ||
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables ||
    networkStatus === NetworkStatus.poll ||
    networkStatus < NetworkStatus.ready;

  const nodes = data?.allAssignedSurveys.edges.map((edge) =>
    mapAssignedSurveyNode(edge.node)
  );
  const pageInfo = data?.allAssignedSurveys.pageInfo;
  const totalNumberFound = data?.allAssignedSurveys.totalNumberFound;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  const handleViewOptionChange = (
    event: SelectChangeEvent<'all' | 'available' | 'required'>
  ) => {
    const value = event.target.value as 'all' | 'available' | 'required';
    setViewOption(value);

    if (value !== 'all') {
      setFilterBy('assignment_type', value);
    } else {
      setFilterBy('assignment_type', '');
    }
  };

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          patientId: patientId,
          first: rowsPerPage,
          after: pageInfo?.endCursor,
          filterBy: {
            filterBy: [filterByDebounced],
          },
          sortBy: {
            sortBy: [sortBy],
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.allAssignedSurveys) return prev;
          return {
            allAssignedSurveys: {
              ...fetchMoreResult.allAssignedSurveys,
              edges: [
                ...prev.allAssignedSurveys.edges,
                ...fetchMoreResult.allAssignedSurveys.edges,
              ],
              pageInfo: fetchMoreResult.allAssignedSurveys.pageInfo,
              totalNumberFound:
                fetchMoreResult.allAssignedSurveys.totalNumberFound,
            },
          };
        },
      });
    }
    setPage(page);
  };

  const onRowsPerPageChange = (rowsPerPage: RowsPerPageOptions) => {
    changeRowsPerPage(rowsPerPage);
  };

  const getRefetchQueries = () => [
    ListAllAssignedSurveysDocument,
    {
      query: ListAllAssignedSurveysDocument,
      variables: {
        patientId: patientId,
        first: rowsPerPage,
        filterBy: {
          filterBy: [filterByDebounced],
        },
        sortBy: {
          sortBy: [sortBy],
        },
      },
    },
  ];

  const handleUnassignSurvey = (row: AllAssignedSurveyProps) => {
    const firstName = patientName?.split(' ')[0];

    openDialog({
      title: 'Are you sure you want to unassign this survey?',
      content: (
        <Typography variant="body1">
          {firstName} will no longer receive this.
        </Typography>
      ),
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Unassign',
      handleConfirm: () => handleConfirmUnassignSurvey(row),
    });
  };

  const handleConfirmUnassignSurvey = async (row: AllAssignedSurveyProps) => {
    try {
      if (row.assignment_type === AssignmentType.Available) {
        await unassignSurveyAnytime({
          variables: {
            patientId: patientId,
            surveyId: row.surveyId,
          },
          refetchQueries: getRefetchQueries(),
        });
      } else {
        await unassignSurvey({
          variables: {
            patientId: patientId,
            surveyId: row.surveyId,
            itemId: `${row.itemId}`,
          },
          refetchQueries: getRefetchQueries(),
        });
      }
    } catch (error) {
      const message = parseBackendError(error, 'Error to unassign survey');

      showSnackbar({
        message,
        severity: 'error',
      });
    } finally {
      closeDialog();
    }
  };

  const openAssignSurvey = (
    row: AllAssignedSurveyProps,
    action: 'edit' | 'assign'
  ) => {
    const weekDays = (
      row.frequency &&
      [
        SurveyFrequency.Biweekly,
        SurveyFrequency.Triweekly,
        SurveyFrequency.Weekly,
      ].includes(row.frequency)
        ? row.weekDays
        : [formatUTCDate(row.starts_at, 'weekDays')]
    ) as WeekDay[];

    openDialog({
      content: (
        <AssignSurvey
          mode="toPatient"
          patientData={{ id: patientId, name: patientName || '' }}
          surveyData={{
            id: row.surveyId,
            name: row.name,
            isAssigned: row.isAssigned,
            startsAt: row.starts_at,
            endsAt: row.ends_at,
            frequency: row.frequency,
            completeWithinDays: row.complete_within_days,
            weekDays,
          }}
          editData={{
            itemId: action === 'edit' && row.itemId ? `${row.itemId}` : null,
            assignmentType: row.assignment_type as
              | AssignmentType
              | null
              | undefined,
          }}
          label={action === 'edit' ? row.label : ''}
          onSuccess={refetch}
          onFinish={closeDialog}
        />
      ),
    });
  };

  const getChip = (label: string) => {
    return <Chip label={label} />;
  };

  const getButton = (row: AllAssignedSurveyProps) => {
    const items = [
      {
        label: 'Unassign',
        handleClick: () => handleUnassignSurvey(row),
        testId: 'UNASSIGN_MENU_ITEM',
      },
    ];
    if (row.assignment_type === AssignmentType.Required) {
      items.unshift({
        label: 'Assign a new Schedule',
        handleClick: () => openAssignSurvey(row, 'assign'),
        testId: 'ASSIGN_NEW_SCHEDULE_MENU_ITEM',
      });
    }
    return (
      <SplitMenu
        label="Edit"
        startIcon="edit-outline"
        items={items}
        mainActionHandler={() => openAssignSurvey(row, 'edit')}
      />
    );
  };

  const getAnytimeSurveyEmptyValue = () => {
    return <Typography color={theme.palette.text.disabled}>--</Typography>;
  };

  const renderRow = (row: AllAssignedSurveyProps) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="SURVEY_SCHEDULE_INTERNAL_NAME">
          {row.label}
        </TableCell>
        <TableCell data-testid="SURVEY_SCHEDULE_NAME">{row.name}</TableCell>
        <TableCell data-testid="SURVEY_SCHEDULE_ASSIGNMENT_TYPE">
          {capitalize(row.assignment_type || '')}
        </TableCell>
        <TableCell>
          {row.assignment_type === AssignmentType.Available
            ? getAnytimeSurveyEmptyValue()
            : row.starts_at
            ? formatUTCDate(row.starts_at)
            : getChip('unassigned')}
        </TableCell>
        <TableCell>
          {row.assignment_type === AssignmentType.Available
            ? getAnytimeSurveyEmptyValue()
            : getFrequency(row.starts_at, row.frequency, row.weekDays) ||
              getChip('none')}
        </TableCell>
        <TableCell>
          {row.assignment_type === AssignmentType.Available
            ? getAnytimeSurveyEmptyValue()
            : row.complete_within_days
            ? completeWithinLabelByValue[row.complete_within_days]
            : getChip('none')}
        </TableCell>
        <TableCell>
          {row.assignment_type === AssignmentType.Available
            ? getAnytimeSurveyEmptyValue()
            : row.ends_at
            ? formatUTCDate(row.ends_at)
            : row.isAssigned
            ? getChip('never')
            : getChip('unassigned')}
        </TableCell>
        {!isCsr && <TableCell>{getButton(row)}</TableCell>}
        <TableCell>
          <Link to={row.surveyId} data-testid="SURVEY_SCHEDULE_DETAILS_BUTTON">
            <Icon
              name="arrow-ios-forward-outline"
              fill={grey[600]}
              size="large"
              data-testid="SCHEDULE_BUTTON"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <BasePage data-testid="SCHEDULES_CONTAINER">
      <BasePage.BackButton to={`/patient/${patientId}/surveys`} />
      <Typography variant="h5">Assignments for {patientName}</Typography>
      <UserIdentifier
        trialName={patientData?.patientUser?.trial?.protocol_number}
        patientName={patientData?.patientUser?.name}
        subjectId={patientData?.patientUser?.subject_id}
      />
      <Stack direction="row" alignItems="baseline" spacing={3} sx={{ mt: 3 }}>
        <SearchableTableHeader<AllAssignedSurveyProps>
          headCells={headCellsForAllAssignedSchedules}
          defaultSearchField="label"
          onSearchChange={setFilterBy}
        />
        <FormControl sx={{ minWidth: 150 }}>
          <Select
            value={viewOption}
            onChange={handleViewOptionChange}
            displayEmpty
            variant="outlined"
            sx={{
              height: 56,
              '& .MuiSelect-select': {
                paddingY: 1.5,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              },
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="available">Available</MenuItem>
            <MenuItem value="required">Required</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Paper data-testid="SCHEDULES_TABLE">
        <Table<AllAssignedSurveyProps>
          actions={!isCsr ? 'right' : undefined}
          headCells={headCellsForAllAssignedSchedules}
          initialOrderBy="label"
          isLoading={isLoading}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          renderRow={renderRow}
          totalRowCount={totalNumberFound}
          rows={(nodes as AllAssignedSurveyProps[]) || []}
          withPagination
          onSortChange={setSortBy}
          emptyState="No results found"
          sx={{
            '& .MuiTableRow-root.loading': {
              height: '60px', // Ensure consistent height for loading rows
            },
          }}
        />
      </Paper>
    </BasePage>
  );
};

export default AllAssignedSchedules;
