import React from 'react';
import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButton as MuiToggleButton,
  styled,
} from '@mui/material';
import { InputError } from '@fdha/web-ui-library';

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  '& .MuiToggleButtonGroup-grouped': {
    borderColor: theme.palette.divider,
    '&:not(:first-of-type)': {
      borderRadius: 15,
      marginLeft: 3,
      border: '0.5px solid',
      borderColor: theme.palette.divider,
    },
    '&:first-of-type': {
      borderRadius: 15,
    },
    '&:not(:last-of-type)': {
      borderRadius: 15,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-disabled': {
      borderColor: theme.palette.divider,
    },
  },
}));

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  borderRadius: 15,
  width: '100%',
  height: 50,
  margin: '0px 3px 12px 3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '0.5px solid',
  borderColor: theme.palette.divider,
}));

export interface ScaleProps {
  value: string;
  options: { label: string; value: string }[];
  error?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const ScalePicker = ({
  options,
  value,
  onChange,
  disabled,
  error,
}: ScaleProps) => {
  const handleChange = (event: React.SyntheticEvent<Element, Event>) => {
    const newValue = (event.target as HTMLInputElement).value;
    onChange?.(newValue);
  };

  return (
    <>
      <ToggleButtonGroup value={value} exclusive onChange={handleChange}>
        {options.map((option) => {
          const dataTestId = `SCALE_OPTION_${option.value}`;

          return (
            <ToggleButton
              key={option.value}
              value={option.value}
              size="large"
              disabled={disabled}
              data-testid={dataTestId}
            >
              {option.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      {error && <InputError error={error} v2={false} />}
    </>
  );
};

export default ScalePicker;
