import { Box } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  ActivityDetectorProvider,
  CachedFiltersProvider,
  FeatureFlagsProvider,
  WebSocketProvider,
} from '@fdha/common-hooks';
import {
  DialogProvider,
  Loader,
  LoadingBarProvider,
  useSnackbar,
  useDialog,
} from '@fdha/web-ui-library';
import { AuthStatus, useAuthStatus } from '@fdha/web-auth';
import { useNavigate } from 'react-router-dom';
import {
  PlatformType,
  useGetProfileLazyQuery,
  UserSessionInfoInput,
  useUpdateUserSessionInfoMutation,
} from '@fdha/graphql-api-admin';
import { Quill } from 'react-quill';

import Routes from './routes/routes';
import PublicRoutes from './routes/public.routes';
import { AppLayout } from './components';

const QuillLink = Quill.import('formats/link');

QuillLink.sanitize = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }
  return url;
};

function App() {
  const client = useApolloClient();
  const authStatus = useAuthStatus();
  const { closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [updateUserSessionInfo] = useUpdateUserSessionInfoMutation();

  const onSignOut = useCallback(async () => {
    showSnackbar({
      severity: 'warning',
      message: 'Session expired. Please log in again.',
      closeOnClickOutside: true,
      closeAutomatically: false,
    });
    closeDialog();
    navigate('/', { replace: true });
  }, [showSnackbar, closeDialog, navigate]);

  const [getProfile] = useGetProfileLazyQuery({
    onCompleted: (result) =>
      updateUserSession(result.me.id, result.me.timezone),
  });

  const updateUserSession = async (
    coachId: string,
    timezone?: string | null
  ) => {
    const coachTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const props: UserSessionInfoInput = {
      platform: PlatformType.Web,
      action: 'activateSession',
    };

    if (coachId && coachTimezone !== timezone) {
      props.timezone = coachTimezone;
    }

    await updateUserSessionInfo({
      variables: {
        props,
      },
    });
  };

  useEffect(() => {
    if (authStatus === AuthStatus.SIGNED_IN) {
      getProfile();
    }
  }, [authStatus, getProfile]);

  useEffect(() => {
    const clearCache = async () => {
      try {
        await client.cache.reset();
      } catch (e) {
        console.log('Error cleaning cache', e);
      }
    };

    if (authStatus === AuthStatus.SIGNED_OUT) {
      clearCache();
    }
  }, [authStatus, client]);

  if (authStatus === AuthStatus.LOADING) {
    return <Loader />;
  }
  return (
    <>
      {authStatus === AuthStatus.SIGNED_IN ? (
        <FeatureFlagsProvider>
          <WebSocketProvider>
            <DialogProvider>
              <AppLayout>
                <ActivityDetectorProvider onSignOut={onSignOut}>
                  <LoadingBarProvider>
                    <CachedFiltersProvider>
                      <Routes />
                    </CachedFiltersProvider>
                  </LoadingBarProvider>
                </ActivityDetectorProvider>
              </AppLayout>
            </DialogProvider>
          </WebSocketProvider>
        </FeatureFlagsProvider>
      ) : (
        <Box width="100%">
          <PublicRoutes />
        </Box>
      )}
    </>
  );
}

export default App;
