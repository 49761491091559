import React, { useEffect } from 'react';
import { NetworkStatus, useApolloClient } from '@apollo/client';
import { Paper, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Table, RowsPerPageOptions } from '@fdha/web-ui-library';
import {
  AnytimeSurveyAssignmentExtended,
  SortByOrder,
  useListAnytimeSurveyAssignmentsQuery,
} from '@fdha/graphql-api-admin';

import { useTable } from '../../../../hooks';
import {
  getAvailableSurveysHeaderCells,
  renderAvailableSurveysTableCells,
} from '../../../../utils/availableSurveysData';

const AvailableSurveys = () => {
  const params = useParams();
  const apolloClient = useApolloClient();

  const patientId = params.patientId || '';

  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: 'availableSurveys',
  });

  useEffect(() => {
    return () => {
      apolloClient.cache.evict({
        fieldName: 'availableSurveys',
      });
    };
  }, [apolloClient.cache]);

  const headCells = getAvailableSurveysHeaderCells();
  const emptyState = 'No available surveys';

  const { data, error, fetchMore, loading, networkStatus } =
    useListAnytimeSurveyAssignmentsQuery({
      variables: {
        patientId: patientId,
        first: rowsPerPage,
        sortBy: {
          sortBy: [
            {
              field: 'id',
              order: SortByOrder.Desc,
            },
          ],
        },
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  const nodes =
    data?.anytimeSurveyAssignments.edges.map((edge) => edge.node) || [];

  const pageInfo = data?.anytimeSurveyAssignments.pageInfo;
  const totalNumberFound = data?.anytimeSurveyAssignments.totalNumberFound;

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  if (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          patientId: patientId,
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const onRowsPerPageChange = (rowsPerPage: RowsPerPageOptions) => {
    changeRowsPerPage(rowsPerPage);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ marginBottom: 1 }}
      >
        <Typography variant="h5" sx={{ marginBottom: 3 }}>
          Available Surveys
        </Typography>
      </Stack>
      <Paper data-testid="AVAILABLE_SURVEYS_TABLE">
        <Table<AnytimeSurveyAssignmentExtended>
          headCells={headCells}
          isLoading={loading}
          totalRowCount={totalNumberFound}
          renderRow={renderAvailableSurveysTableCells}
          page={page}
          emptyState={emptyState}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          rows={nodes}
          withPagination
        />
      </Paper>
    </>
  );
};

export default AvailableSurveys;
