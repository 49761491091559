import React from 'react';
import { formatDate, HeadCell } from '@fdha/web-ui-library';
import { TableCell, Typography } from '@mui/material';
import { SurveyResponse } from '@fdha/graphql-api-admin';

import Chip from '../components/Chip/Chip';

import { getChipColor, getChipLabel } from './surveysData';

export const getSurveyResponsesHeaderCells = (): HeadCell<SurveyResponse>[] => {
  const headCells: HeadCell<SurveyResponse>[] = [
    {
      id: 'label',
      label: 'Internal name',
      sortable: true,
      searchable: true,
    },
    {
      id: 'assignment_type',
      label: 'Type',
      sortable: false,
      searchable: false,
    },
    {
      id: 'survey_date',
      label: 'Survey date',
      sortable: true,
      searchable: false,
    },
    {
      id: 'survey_status',
      label: 'Status',
      sortable: false,
      searchable: false,
    },
    {
      id: 'completed_at',
      label: 'Completed at',
      sortable: true,
      searchable: false,
    },
  ];

  return headCells;
};

export const renderSurveyResponsesTableCells = (
  row?: SurveyResponse,
  userId?: string,
  patientId?: string
): JSX.Element => {
  const surveyDate = row?.survey_date
    ? formatDate(new Date(row.survey_date))
    : null;
  const completedAt = row?.completed_at
    ? formatDate(new Date(row.completed_at))
    : null;

  const {
    label,
    assignment_type: assignmentType,
    survey_status: surveyStatus,
    completed_by: completedById,
  } = row || {};
  let createdByName = row?.created_by_name || '';

  createdByName =
    completedById === userId ? 'You' : `Coach ${createdByName.split(' ')[0]}`;
  return (
    <>
      <TableCell data-testid="SURVEY_INSTANCE_INTERNAL_NAME_CELL">
        {label}
      </TableCell>
      <TableCell
        data-testid="SURVEY_INSTANCE_ASSIGNMENT_TYPE_CELL"
        sx={{
          '&::first-letter': {
            textTransform: 'uppercase',
          },
        }}
      >
        {assignmentType ?? ''}
      </TableCell>
      <TableCell data-testid="SURVEY_INSTANCE_DUE_DATE_CELL">
        {surveyDate ?? ''}
      </TableCell>
      <TableCell data-testid="SURVEY_INSTANCE_STATUS_CELL">
        <Chip
          label={getChipLabel(surveyStatus)}
          color={getChipColor(surveyStatus)}
        />
      </TableCell>
      <TableCell data-testid="SURVEY_INSTANCE_COMPLETED_DATE_CELL">
        {completedAt && (
          <>
            <Typography variant="body1">{completedAt}</Typography>
            {patientId !== completedById && createdByName && (
              <Typography variant="subtitle2">{`(${createdByName})`}</Typography>
            )}
          </>
        )}
      </TableCell>
    </>
  );
};
