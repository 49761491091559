import React from 'react';

import LoginBase, { LoginBaseProps } from '../components/LoginBase';
import { Login } from '../components';

export interface LoginPageProps extends LoginBaseProps {
  onSignIn?: () => void;
}

export const LoginPage: React.FC<LoginPageProps> = ({
  onSignIn,
  ...loginBaseProps
}) => {
  return (
    <LoginBase {...loginBaseProps}>
      <Login onSignIn={onSignIn} />
    </LoginBase>
  );
};
