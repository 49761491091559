import React from 'react';
import { Loader, Table } from '@fdha/web-ui-library';
import {
  Paper as MuiPaper,
  styled,
  TableCell,
  TableRow,
  Box,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useParams } from 'react-router-dom';
import Icon from 'react-eva-icons';
import {
  AssignmentType,
  SurveyQuestion,
  SurveyQuestionType,
  SurveyResponse,
  SurveyStatus,
  useGetAnytimeSurveyResponseQuery,
  useGetProfileQuery,
} from '@fdha/graphql-api-admin';

import {
  getSurveyResponsesHeaderCells,
  renderSurveyResponsesTableCells,
} from '../../../../utils';

import SurveyQuestionView from './SurveyQuestionView';

export const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: '12px 24px',
  maxWidth: '857px',
  width: '100%',
  marginBottom: theme.spacing(3),
  boxShadow: theme.shadows[2],
  alignSelf: 'center',
}));

const AnytimeSurveyPreview = () => {
  const { responseId } = useParams();

  const { data, loading } = useGetAnytimeSurveyResponseQuery({
    variables: {
      responseId: responseId || '',
    },
    fetchPolicy: 'cache-and-network',
    skip: !responseId,
  });

  const { data: profileData } = useGetProfileQuery();

  if (loading) {
    return <Loader />;
  }

  if (!data?.anytimeSurveyResponse) {
    return <Typography>{`No survey found with ID ${responseId}`}</Typography>;
  }

  const userId = profileData?.me.id;

  const survey = data.anytimeSurveyResponse;

  const label = `${survey.name} ${survey.label ? '- ' + survey.label : ''}`;

  const rows: SurveyResponse[] = [
    {
      id: survey.id,
      label,
      assignment_type: AssignmentType.Available,
      survey_date: survey.respondedAt,
      survey_status: SurveyStatus.Done,
      completed_at: survey.createdAt,
      completed_by: survey.createdBy,
      created_by_name: survey.createdByName,
    },
  ];

  const renderRow = (row?: SurveyResponse) => {
    return (
      <TableRow hover key={row?.id} data-testid="TABLE_ROW">
        <TableCell>
          <Link to="../../" data-testid="SURVEYS_BACK_BUTTON">
            <Icon
              name="arrow-ios-back-outline"
              fill={grey[600]}
              size="large"
              data-testid="SURVEYS_BUTTON"
            />
          </Link>
        </TableCell>
        {renderSurveyResponsesTableCells(row, userId, survey.patientId)}
      </TableRow>
    );
  };

  return (
    <>
      <MuiPaper
        data-testid="ANYTIME_SURVEYS_PREVIEW_TABLE"
        sx={{ marginBottom: 7 }}
      >
        <Table<SurveyResponse>
          actions="left"
          headCells={getSurveyResponsesHeaderCells()}
          renderRow={renderRow}
          rows={rows}
        />
      </MuiPaper>
      <Box
        display="flex"
        flexDirection="column"
        data-testid="ANYTIME_SURVEY_PREVIEW_CONTAINER"
      >
        {data?.anytimeSurveyResponse.questions
          ?.filter(
            (question) => question.type !== SurveyQuestionType.Explanation
          )
          .map((item: SurveyQuestion, index: number) => (
            <Paper
              data-testid="ANYTIME_SURVEY_QUESTION_CONTAINER"
              key={item.id}
            >
              <SurveyQuestionView item={item} questionNumber={index} />
            </Paper>
          ))}
      </Box>
    </>
  );
};

export default AnytimeSurveyPreview;
