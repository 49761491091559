import {
  GetPatientUserDocument,
  useGetPatientLanguagesQuery,
  useGetPatientUserQuery,
  useUpdatePatientLanguageMutation,
} from '@fdha/graphql-api-admin';
import {
  BasePage,
  Loader,
  RadioGroup,
  Typography,
  Warning,
} from '@fdha/web-ui-library';
import { Paper, Stack } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { UserIdentifier } from '../../components';

const LanguageSelector = () => {
  const navigate = useNavigate();
  const params = useParams();
  const patientId = params.patientId || '';

  const { data: patientLanguagesData, loading: languagesLoading } =
    useGetPatientLanguagesQuery({
      variables: {
        patientId,
      },
    });

  const { data: patientData, loading: patientLoading } = useGetPatientUserQuery(
    {
      variables: {
        id: patientId,
      },
    }
  );

  const [updatePatientLanguage, { loading: updateLanguageLoading }] =
    useUpdatePatientLanguageMutation({
      refetchQueries: [GetPatientUserDocument],
      awaitRefetchQueries: true,
    });

  const availableLanguages =
    patientLanguagesData?.patientLanguages?.map((language) => ({
      label: language.nameEnglish ?? '',
      value: language.code ?? '',
    })) || [];

  const selectedLanguage = patientData?.patientUser?.language ?? '';

  const isLoading = languagesLoading || patientLoading;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <BasePage data-testid="PATIENT_LANGUAGE_CONTAINER">
      <BasePage.BackButton handleClick={() => navigate(-1)} />
      <Stack spacing={3} maxWidth="918px" alignSelf="center">
        <Stack spacing={0.5}>
          <Typography variant="h5">Language</Typography>
          <UserIdentifier
            trialName={patientData?.patientUser?.trial?.protocol_number}
            patientName={patientData?.patientUser?.name}
            subjectId={patientData?.patientUser?.subject_id}
          />
        </Stack>
        <Paper>
          <Stack spacing={3} m={3}>
            <Typography variant="body1">
              Language preference ensures that all interactions between the
              patient and Faeth occur in their preferred language, including
              emails, chat, app language, and deliveries.
            </Typography>
            <Warning text="Changing the patient’s preferred language will update their app language immediately." />
            {updateLanguageLoading ? (
              <Loader />
            ) : (
              <RadioGroup
                showDivider
                type="default"
                value={selectedLanguage}
                options={availableLanguages}
                onChange={(value) => {
                  updatePatientLanguage({
                    variables: {
                      patientId,
                      language: value,
                    },
                  });
                }}
              />
            )}
          </Stack>
        </Paper>
      </Stack>
    </BasePage>
  );
};

export default LanguageSelector;
