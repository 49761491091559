import { Stack, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { studyDietIconByValue, StudyDietOptions } from '@fdha/common-utils';

import { Typography } from '../Typography/Typography';
import { formatDate, formatHour } from '../../utils';
import { Icon, IconProps } from '../Icon/Icon';

export interface DetailedBHBCardProps {
  date: Date;
  bhbLevels: {
    bhb: number;
    time: Date;
  }[];
  studyDiet?: StudyDietOptions;
}

export const DetailedBHBCard: FC<DetailedBHBCardProps> = ({
  date,
  bhbLevels,
  studyDiet,
}) => {
  const theme = useTheme();

  const getIconProps = (): IconProps => {
    return {
      name: studyDiet
        ? studyDietIconByValue[studyDiet]
        : 'question-mark-outline',
      fill: studyDiet ? theme.palette.info.main : theme.palette.text.hint,
      size: 'large',
    };
  };

  return (
    <Stack
      p={5}
      border={1}
      borderRadius="12px"
      borderColor={theme.palette.divider}
      boxShadow={theme.shadows[2]}
      spacing={3}
    >
      <Typography variant="h6">
        Detailed view for {formatDate(new Date(date), 'smallMonthDayYear')}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Stack spacing={3} flex={1}>
          <Typography variant="subtitle2" color={theme.palette.text.secondary}>
            BHB (this day)
          </Typography>
          <Stack spacing={1}>
            {bhbLevels.map((bhbLevel, index) => (
              <Stack
                key={index}
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography variant="h5">{bhbLevel.bhb.toFixed(1)}</Typography>
                <Typography variant="caption">
                  {formatHour(bhbLevel.time, '12HoursWithZero')}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack spacing={3} flex={1}>
          <Typography variant="subtitle2" color={theme.palette.text.secondary}>
            ISD (day before)
          </Typography>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Icon {...getIconProps()} />
            <Typography variant="h6" fontWeight={400}>
              {studyDiet ?? 'Missing'}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
