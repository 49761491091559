import React, { FC, useContext, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Icon from 'react-eva-icons';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import { Button } from '@fdha/web-ui-library';
import { useReactiveVar } from '@apollo/client';
import { FormBuilderQuestionType } from '@fdha/graphql-api-admin';

import { QuestionCardType } from '../../../models/FormBuilderProps';
import {
  removeQuestion,
  setQuestionValue,
  questions as questionsState,
} from '../../../states/questionBuilderState';
import DragQuestionCard from '../DragQuestionCard';
import { FormBuilderContext } from '../FormBuilder';

import LogicTabPanel from './LogicTabPanel/LogicTabPanel';
import OptionsTabPanel from './OptionsTabPanel/OptionsTabPanel';
import EditTabPanel from './EditTabPanel/EditTabPanel';
import Tabs from './Tabs/Tabs';

export interface CardProps {
  item: QuestionCardType;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  enableRTE?: boolean;
}

const EditQuestionCard: FC<CardProps> = ({
  item,
  index,
  moveCard,
  enableRTE = false,
}) => {
  const { hasLogic } = useContext(FormBuilderContext);
  const questions = useReactiveVar(questionsState);

  const tabs = ['Edit', 'Options', ...(hasLogic ? ['Logic'] : [])];
  const [tab, setTab] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const requiredMessage = 'This field is required';
  const validationSchema = {
    title: Yup.string().trim().required(requiredMessage),
    placeholderText: Yup.string().trim().required(requiredMessage),
    maxLength: Yup.number()
      .integer('Must be an integer number')
      .min(1, 'Must be more than 1')
      .max(500, 'Must be less than or equal to 500')
      .required(requiredMessage),
    possibleAnswers: Yup.array().of(Yup.string().required(requiredMessage)),
    maxScale: Yup.number().integer().min(3).max(10).required(requiredMessage),
  };

  const getValidationSchema = () => {
    const defaultValues = {
      title: validationSchema.title,
    };

    switch (item.type) {
      case FormBuilderQuestionType.OpenText:
        return {
          ...defaultValues,
          placeholderText: validationSchema.placeholderText,
          maxLength: validationSchema.maxLength,
        };
      case FormBuilderQuestionType.Number:
        return {
          ...defaultValues,
          placeholderText: validationSchema.placeholderText,
        };
      case FormBuilderQuestionType.MultipleChoice:
      case FormBuilderQuestionType.SingleChoice:
      case FormBuilderQuestionType.Binary:
        return {
          ...defaultValues,
          possibleAnswers: validationSchema.possibleAnswers,
        };
      case FormBuilderQuestionType.Scale:
        return {
          ...defaultValues,
          maxScale: validationSchema.maxScale,
        };
      default:
        return defaultValues;
    }
  };

  const validate = (values: QuestionCardType) => {
    let errors: FormikErrors<{ title: string }> = {};

    const duplicatedQuestion = questions.find(
      (question) =>
        question.title.trim() === values.title.trim() &&
        question.id !== values.id
    );

    if (duplicatedQuestion) {
      errors.title = 'Question title needs to be unique';
    }

    return errors;
  };

  const handleErrors = (
    errors: FormikErrors<QuestionCardType>,
    touched: FormikTouched<QuestionCardType>
  ) => {
    return {
      title: touched.title && errors.title,
      placeholderText: touched.placeholderText && errors.placeholderText,
      maxLength: touched.maxLength && errors.maxLength,
      errorMessage: touched.errorMessage && errors.errorMessage,
      possibleAnswers: {
        errors: errors.possibleAnswers as string[] | undefined,
        touched: touched.possibleAnswers as boolean[] | undefined,
      },
      maxScale: errors.maxScale,
    };
  };

  const handleSubmit = (values: QuestionCardType) => {
    let newValues = { ...values, isEditing: false };

    if (values.type === FormBuilderQuestionType.Scale) {
      const minValue = 1;
      const maxValue = values.maxScale || 0;
      const possibleAnswers = Array.from(
        { length: maxValue - minValue + 1 },
        (_, i) => (i + minValue).toString()
      );
      newValues = {
        ...newValues,
        possibleAnswers,
      };
    }

    setQuestionValue(newValues);
  };

  const getInitialValues = () => {
    if (item.type === FormBuilderQuestionType.Scale && item.possibleAnswers) {
      const possibleAnswers = item.possibleAnswers.map((answer) =>
        parseInt(answer)
      );
      const maxScale = Math.max.apply(null, possibleAnswers);

      return { ...item, maxScale };
    }

    return item;
  };

  return (
    <DragQuestionCard item={item} index={index} moveCard={moveCard}>
      <Formik
        initialValues={getInitialValues()}
        validate={validate}
        validationSchema={Yup.object(getValidationSchema())}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => {
          const errorState = handleErrors(errors, touched);

          function handleOptionChange<T>(fieldName: string, value: T) {
            setFieldValue(fieldName, value);
          }

          return (
            <Form>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tabs
                  tabs={tabs}
                  selectedTab={tab}
                  handleChangeTab={handleChangeTab}
                  errorState={errorState}
                />
                <Typography fontWeight="500">{item.name}</Typography>
              </Box>
              <EditTabPanel
                hidden={tab !== 0}
                values={values}
                errorState={errorState}
                handleChange={handleChange}
                enableRTE={enableRTE}
              />
              <OptionsTabPanel
                hidden={tab !== 1}
                values={values}
                errorState={errorState}
                handleChange={handleOptionChange}
              />
              <LogicTabPanel
                hidden={tab !== 2}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <Box display="flex" justifyContent="flex-end" marginTop={1}>
                <IconButton
                  onClick={() => removeQuestion(index)}
                  data-testid="REMOVE_BUTTON_EDIT_QUESTION"
                >
                  <Icon name="trash-2-outline" size="large" fill={grey[600]} />
                </IconButton>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  data-testid="SAVE_BUTTON_EDIT_QUESTION"
                  sx={{ marginLeft: '12px' }}
                >
                  Save
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </DragQuestionCard>
  );
};

export default EditQuestionCard;
